/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  lastValueFrom,
  takeUntil,
} from 'rxjs';
import {Song} from '../interfaces/Song';
import {MoodMetadata} from '../interfaces/MoodMetadata';
import {MoodPlayerService} from './mood-player.service';
import {Slot} from '../interfaces/Slot';
import {StorageService} from './storage.service';
import {IPlaylistStatus, PlaylistStatus} from '../interfaces/IPlaylistStatus';
import {WaveSurferService} from './wave-surfer.service';
import {createWaveSurferService} from '../factories/wavesurferFactory';
import WaveSurfer from 'wavesurfer.js';
import {DebugPlayLog} from "../interfaces/Playlog";

@Injectable({
  providedIn: 'root',
})
export class PlaylistPlayerService implements OnDestroy {
  public CurrentTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public allDownloadsCompleted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public DownloadingCountDown: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  public PlayingSong: BehaviorSubject<Song | null> =
    new BehaviorSubject<Song | null>(null);
  public PlaylistStatus: Subject<IPlaylistStatus | null> =
    new Subject<IPlaylistStatus | null>();
  private remainingSongsToDownload = 0;
  private downloadCompleted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private checkForSongToPlayTimer: NodeJS.Timer = {} as NodeJS.Timer;
  private checkForObsoleteSongsTimer: NodeJS.Timer = {} as NodeJS.Timer;
  private getMetadata: Observable<MoodMetadata> | undefined;
  public InitialThresholdDownloaded = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();
  private mixingInterval = 100;
  private monitoring = false;
  private monitorInterval: NodeJS.Timeout | undefined;

  private playlist: Song[] = [];
  private songA: Song | undefined | null;
  private songB: Song | undefined | null;

  private playlistSongs = new Subject<Song[]>();

  public waveSurferServiceA: WaveSurferService;
  public waveSurferServiceB: WaveSurferService;

  private currentTimeA = 0;
  private currentTimeB = 0;

  private activePlayer: 'A' | 'B' = 'A';
  private currentIndex$ = new BehaviorSubject<number>(0);
  private songPlayingA$ = new Subject<number | null>();
  private songPlayingB$ = new Subject<number | null>();
  private startPlayingAuto$ = new Subject<{ song: number; startTime: any }>();
  private isMixing = false;
  private isFadingOut = false;
  private stopTime = 0;

  private playedOnce = false;
  private dummyPeaks = [[0]];
  private playerVolume = 1;
  private isProcessing = false;
  metadata: MoodMetadata = {} as MoodMetadata;
  isProcessingNextSong = false;
  public slotProcessed$: BehaviorSubject<Slot | undefined> = new BehaviorSubject<
    Slot | undefined
  >(undefined);
  public percentageToCompleteAllSongs: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private moodPlayerService: MoodPlayerService,
    private injector: Injector,
    private storageService: StorageService
  ) {
    this.waveSurferServiceA = createWaveSurferService(this.injector);
    this.waveSurferServiceB = createWaveSurferService(this.injector);
    this.mixingInterval = this.isMobileDevice() ? 100 : this.mixingInterval;
    //Dynamically create these two divs in the DOM, as the WaveSurferService will need them to create the WaveSurfer instances.
    //make the waveforms hidden, as they are not needed to be seen by the user.
    const waveformA = document.createElement('div');
    waveformA.id = 'waveformA';
    waveformA.style.display = 'none';
    document.body.appendChild(waveformA);

    const waveformB = document.createElement('div');
    waveformB.id = 'waveformB';
    waveformB.style.display = 'none';
    document.body.appendChild(waveformB);


    this.playlistSongs.subscribe((songs) => {
      this.playlist = [...songs];
    });
    this.slotProcessed$.subscribe((slot) => {
      console.debug('assigning audio urls');
      for (let i = 0; i < this.playlist.length; i++) {
        if (
          this.playlist[i].audioURL === undefined ||
          this.playlist[i].audioURL === ''
        ) {
          const processedSong = slot?.songs.find(
            (s) => s.id === this.playlist[i].id
          );
          if (processedSong) {
            this.playlist[i].audioURL = processedSong.audioURL;
          } else {
            console.warn('not found a processed audio urls');
          }
        }
      }
    });
    this.startPlayingAuto$.subscribe(async ({song, startTime}) => {
      console.debug('startPlayingAuto$' + JSON.stringify({song, startTime}));
      await this.playSong(song);
      this.PlayingSong.next(this.playlist[song]);
      this.notify(PlaylistStatus.playing, new Date(startTime));
    });
  }

  setWaveSurfers(waveSurferA: WaveSurfer, waveSurferB: WaveSurfer) {
    this.waveSurferServiceA.waveSurfer = waveSurferA;
    this.waveSurferServiceB.waveSurfer = waveSurferB;
  }

  getSongPlayingA$(): Observable<number | null> {
    return this.songPlayingA$.asObservable();
  }

  getSongPlayingB$(): Observable<number | null> {
    return this.songPlayingB$.asObservable();
  }

  isMobileDevice(): boolean {
    // Regex pattern for mobile user agents
    const mobileRegex = /iPhone|iPad|iPod|Android/i;
    return mobileRegex.test(navigator.userAgent);
  }

  private setSongPlayingA(id: number | null) {
    this.songPlayingA$.next(id);
  }

  private setSongPlayingB(id: number | null) {
    this.songPlayingB$.next(id);
  }

  getCurrentIndex$(): Observable<number> {
    return this.currentIndex$.asObservable();
  }

  getCurrentIndex(): number {
    return this.currentIndex$.value;
  }

  private setCurrentIndex(value: number) {
    this.currentIndex$.next(value);
  }

  private async runMonitor() {
    this.monitoring = true;
    this.currentTimeA = this.waveSurferServiceA.getCurrentTime();
    this.currentTimeB = this.waveSurferServiceB ? this.waveSurferServiceB.getCurrentTime() : 0; // in the event that we are on the last song, it will be null, thus currentTime is 0.
    await this.mixMusic();
    //End of the Playlist
    if (this.getCurrentIndex() > this.playlist.length - 1) {
      console.log('End of Playlist');
      this.stopMonitor();
      return;
    }
  }

  private startMonitor(): void {
    if (!this.monitoring) {
      this.monitorInterval = setInterval(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        async () => await this.runMonitor(),
        this.mixingInterval
      );
    } else {
      console.warn('Monitor already running');
    }
  }

  private saveDebugPlayLogToLocalStorage(debugPlayLog: DebugPlayLog) {
    const debugPlayLogs = JSON.parse(localStorage.getItem('debugPlayLogs') || '[]');
    debugPlayLogs.push(debugPlayLog);
    localStorage.setItem('debugPlayLogs', JSON.stringify(debugPlayLogs));
    console.log('PlayLog saved to local storage.');
  }

  private removeDebugPlayLogFromLocalStorage(debugPlayLog: DebugPlayLog) {
    let debugPlayLogs = JSON.parse(localStorage.getItem('debugPlayLogs') || '[]');
    debugPlayLogs = debugPlayLogs.filter((log: DebugPlayLog) => log.id !== debugPlayLog.id);
    localStorage.setItem('debugPlayLogs', JSON.stringify(debugPlayLogs));
    console.log('PlayLog removed from local storage.');
  }

  private uploadDebugPlayLog(log: DebugPlayLog) {
    this.moodPlayerService.uploadDebugPlayLogs(log).subscribe({
      next: () => {
        console.log('PlayLog saved to server.');
        this.removeDebugPlayLogFromLocalStorage(log);
      },
      error: (error) => {
        console.error('Failed to save PlayLog to server. Retrying...', error);
        setTimeout(() => {
          this.uploadDebugPlayLog(log);
        }, 600000); // retry after 10 minutes
      }
    });
  }

  private uploadAllDebugPlayLogs() {
    const debugPlayLogs = JSON.parse(localStorage.getItem('debugPlayLogs') || '[]');
    debugPlayLogs.forEach((log: DebugPlayLog) => {
      this.uploadDebugPlayLog(log);
    });
  }

  private stopMonitor(): void {
    clearInterval(this.monitorInterval);
    this.monitoring = false;
  }

  private async initializeSongs(index = 0) {
    if (this.playlist.length === 0) return;
    let audioStreamUrl1 = this.songA!.audioURL;
    console.debug('audioStreamUrl1', audioStreamUrl1);
    this.activePlayer = 'A';
    this.isMixing = false;
    this.isFadingOut = false;
    this.stopTime = 0;
    this.playedOnce = false;

    if (!audioStreamUrl1 || audioStreamUrl1 === '') {
      console.warn(`No Audio Stream for song ${JSON.stringify(this.songA)}`);
      const audioURL = await this.downloadAndStoreSong(this.songA!);
      this.songA!.audioURL = audioURL;
      audioStreamUrl1 = audioURL;
    }
    const unsubscribeA = this.waveSurferServiceA.waveSurfer?.on('ready', () => {

      this.waveSurferServiceA.waveSurfer?.on('play', () => {
        // check if waveSurferServiceB.waveSurfer is playing
        if (unsubscribeA)
          unsubscribeA();
        if (this.waveSurferServiceB.waveSurfer?.isPlaying()) {
          // check if songB is before mix time
          if (this.songB && this.songB.mixTime > this.waveSurferServiceB.waveSurfer?.getCurrentTime()) {
            console.error('********************** songB is before mix time');
            const playLog = new DebugPlayLog(
              this.metadata, this.songA, this.songB, this.getCurrentIndex(), this.playlist, this.isMixing,
              this.isProcessingNextSong, this.isFadingOut, new Date().toISOString(), "B", "A");
            try {
              this.saveDebugPlayLogToLocalStorage(playLog)
              this.uploadAllDebugPlayLogs();
            } catch (e: any) {
              console.error('error saving play log', e.message);
            }
          }
        }
      });

      this.startMonitor();
      this.Play();
    });

    const unsubscribeB = this.waveSurferServiceB.waveSurfer?.on('ready', () => {

      // check if waveSurferServiceA.waveSurfer is playing
      this.waveSurferServiceB.waveSurfer?.on('play', () => {
        // check if waveSurferServiceA.waveSurfer is playing
        if (unsubscribeB)
          unsubscribeB();
        if (this.waveSurferServiceA.waveSurfer?.isPlaying()) {
          // check if songA is before mix time
          if (this.songA && this.songA.mixTime > this.waveSurferServiceA.waveSurfer?.getCurrentTime()) {
            console.error('********************** songA is before mix time');
            const playLog = new DebugPlayLog(
              this.metadata, this.songA, this.songB, this.getCurrentIndex(), this.playlist, this.isMixing,
              this.isProcessingNextSong, this.isFadingOut, new Date().toISOString(), "A", "B");
            try {
              this.saveDebugPlayLogToLocalStorage(playLog)
              this.uploadAllDebugPlayLogs();
            } catch (e: any) {
              console.error('error saving play log', e.message);
            }
          }
        }
      });
    });


    this.waveSurferServiceA.getWaveSurfer(
      '#waveformA',
      audioStreamUrl1,
      this.dummyPeaks,
      this.songA!.duration
    );
    // If the song is the first song in the playlist, we want to start the song at the start time.
    // Otherwise, we want to start the song at the triggerFadeOutTime - 5 seconds. of the Previous Song.
    //  if (this.getCurrentIndex() === 0 && index === 0) {
    this.waveSurferServiceA.setTime(this.songA!.startTime);
    if (this.songA) {
      if (this.songA.mixTime <= 0) {
        this.songA.mixTime = this.songA.duration;
        console.debug('setting mix time to song duration', this.songA.mixTime);
      }

      if (this.songA.mixTime > this.songA.duration) {
        this.songA.mixTime = this.songA.duration;
      }
      this.setVolumeA(this.songA.volume * this.playerVolume);
    }
    // } else {
    //   const triggerFadeOutTime =
    //     this.songA!.fadeOut < 0
    //       ? this.songA!.mixTime - Math.abs(this.songA!.fadeOut)
    //       : this.songA!.mixTime;
    //   this.waveSurferServiceA.seekTo(triggerFadeOutTime - 5)
    //   this.setVolume(this.songA!.volume * this.playerVolume);
    // }

    // this.waveSurferServiceB.destroy();
    if (this.songB) {
      if (this.songB.mixTime <= 0) {
        this.songB.mixTime = this.songB.duration;
        console.debug('setting mix time to song duration', this.songB.mixTime);
      }

      if (this.songB.mixTime > this.songB.duration) {
        this.songB.mixTime = this.songB.duration;
      }
      let audioStreamUrl2 = this.songB.audioURL;
      if (!audioStreamUrl2 || audioStreamUrl2 === '') {
        console.warn(`No Audio Stream for song ${JSON.stringify(this.songB)}`);
        const audioURL = await this.downloadAndStoreSong(this.songB);
        this.songB.audioURL = audioURL;
        audioStreamUrl2 = audioURL;
      }
      this.waveSurferServiceB.getWaveSurfer(
        '#waveformB',
        audioStreamUrl2,
        this.dummyPeaks,
        this.songB.duration
      );

      this.waveSurferServiceB.seekTo(this.songB.startTime);
      if (this.songB) this.setVolumeB(this.songB.volume * this.playerVolume);
      //Investigate if I need to set the volume here?
    }
  }

  setSlotSongs(songs: Song[]) {
    // The very first time the service is initialized, we want to subscribe to the playlistSongs subject.
    // This will be called every time the playlist is changed, and we will get the latest playlist, when the play button is pressed.
    this.Stop();
    this.playlistSongs.next(songs);
  }

  // *********************************************************************************************************************
  // *********************************************************************************************************************
  // Start of playing logic - This will be moved in a separate service.
  // *********************************************************************************************************************
  // *********************************************************************************************************************
  public async InitializeService(metadata: MoodMetadata) {
    this.subscribeToAllDownloadsCompleted();
    await this.processMetadata(metadata);
    this.startCleaningProcess(24 * 60 * 60 * 1000, metadata);
  }

  public async Start(getMetadata$: Observable<MoodMetadata>) {
    this.getMetadata = getMetadata$;
    this.Stop();

    try {
      // Convert the Observable to a Promise and await its completion
      const metadata = await lastValueFrom(
        getMetadata$.pipe(takeUntil(this.destroy$)),
        {
          defaultValue: undefined, // Provide a default value in case the Observable completes without emitting any values
        }
      );
      if (metadata) this.storageService.setMetadata(metadata);
      console.debug('load meta started');
      await this.loadMetaAndStart();
    } catch (e: any) {
      console.error('error getting metadata', e.message);
      await this.loadMetaAndStart();
    }
    console.debug('load meta ended');
  }

  private async loadMetaAndStart() {
    const metadata = this.storageService.getMetadata();
    this.metadata = metadata;
    await this.InitializeService(metadata);
    const slot = this.findActiveSlot(metadata);
    if (!slot) return;

    await this.startPlayProcess(metadata, slot);

    console.debug('***Starting Player ', this.activePlayer);
  }

  private async startPlayProcess(metadata: MoodMetadata, slot: Slot) {
    const activeSlot = slot;
    // Start Playing.
    if (activeSlot) {
      activeSlot.songs = activeSlot?.songs.filter(
        (s) => new Date(s.endDatetime) >= new Date()
      );
      if (activeSlot.songs.length === 0) {
        // Notify and return
        this.notify(PlaylistStatus.not_found, new Date());
        return;
      }
      // iterate through all activeSlot songs and add the value of audioURL and then return the song

      for (let i = 0; i < activeSlot.songs.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const audioBlob = await this.storageService.getAudio(
          activeSlot.songs[i].id.toString()
        );

        if (audioBlob) {
          activeSlot.songs[i].audioURL = URL.createObjectURL(audioBlob);
        } else if (!this.isProcessing) {
          try {
            const blob = await this.moodPlayerService.getSongById(
              activeSlot.songs[i].id
            );
            const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
            const audioUrl = URL.createObjectURL(audioBlob);
            activeSlot.songs[i].audioURL = audioUrl;
            await this.storageService.storeAudio(
              activeSlot.songs[i].id.toString(),
              activeSlot.songs[i].endDatetime,
              audioBlob
            );
            console.debug('loading music from the API', activeSlot.songs[i].id);
          } catch (e: any) {
            console.error('error downloading song', e.message);
          }
        } else if (this.isProcessing) {
          console.warn('isProcessing', this.isProcessing);
        }
      }

      this.setSlotSongs([...activeSlot.songs]);

      await this.startCheckingForActiveSong(activeSlot, 1000, metadata);
    }
  }

  private startCheckingForActiveSong(
    slot: Slot,
    checkInterval: number,
    metadata: MoodMetadata
  ): Promise<void> {
    // Clear any existing check to avoid multiple checks running simultaneously
    clearTimeout(this.checkForSongToPlayTimer);

    // Wrap the recursive checking logic in a function
    const checkConditionAndResolve = (resolve: any) => {
      this.checkForSong(slot, metadata)
        .then((result) => {
          if (result) {
            // If the condition is met, resolve the promise
            resolve();
          } else {
            // If not, re-schedule the check after the specified interval
            this.checkForSongToPlayTimer = setTimeout(
              () => checkConditionAndResolve(resolve),
              checkInterval
            );
          }
        })
        .catch((error) => {
          console.error('Error checking for song:', error);
          // Optionally, reject the promise or handle the error appropriately
          // This example just logs the error and retries
          this.checkForSongToPlayTimer = setTimeout(
            () => checkConditionAndResolve(resolve),
            checkInterval
          );
        });
    };

    // Return a new promise and initiate the recursive checking
    return new Promise((resolve) => {
      checkConditionAndResolve(resolve);
    });
  }

  private async NotifyStatus(metadata: MoodMetadata, isFirstSlot = false) {
    const activeSlot = this.findActiveSlot(metadata);

    if (!activeSlot) {
      const futureSlot = this.findFutureSlot(metadata);

      // scenario that we exit.
      if (!futureSlot) {
        this.PlaylistStatus.next({
          startingTime: new Date(),
          status: PlaylistStatus.not_found,
        });
        this.Stop();
        return;
      }

      // there is a slot in the future starting
      if (futureSlot && !isFirstSlot) {
        const timeDifference = this.hourDifferenceFromNow(
          new Date(futureSlot.startTime)
        );
        if (timeDifference > 8) {
          this.notify(PlaylistStatus.long_wait, new Date(futureSlot.startTime));
          this.Stop();
          return;
        }

        if (timeDifference <= 8) {
          this.notify(
            PlaylistStatus.short_wait,
            new Date(futureSlot.startTime)
          );
          this.setSlotSongs(futureSlot.songs);
          await this.startCheckingForActiveSong(futureSlot, 1000, metadata);
          return;
        }
      }
    }
  }

  private hourDifferenceFromNow(datetime: Date): number {
    // Get the current datetime
    const currentDatetime = new Date();

    // Calculate the difference in milliseconds
    const timeDifferenceMs = datetime.getTime() - currentDatetime.getTime();

    // Calculate the number of hours
    const hoursDifference = timeDifferenceMs / (1000 * 60 * 60);

    // Check if the difference is greater than 8 hours
    return hoursDifference;
  }

  private startCleaningProcess(interval: number, metadata: MoodMetadata) {
    clearInterval(this.checkForObsoleteSongsTimer);
    this.checkForObsoleteSongsTimer = setInterval(() => {
      const futureSongs = metadata.slots
        .map((s) =>
          s.songs.filter((song) => {
            const currentDateTime = new Date(song.startDatetime);
            return (
              new Date(
                currentDateTime.setMinutes(currentDateTime.getMinutes() + 15)
              ) >= new Date()
            );
          })
        )
        .flat();
      this.storageService
        .disposeAnyObsoleteSongs(futureSongs.map((s) => s.id))
        .then((count) => {
          if (count > 0) console.debug('disposed', count, 'songs');
        })
        .catch((e) => {
          console.error('error disposing songs', e.message);
        });
    }, interval);
  }

  private async processMetadata(metadata: MoodMetadata) {
    if (metadata.slots.length === 0) {
      this.notify(PlaylistStatus.not_found, new Date());
      return;
    }
    metadata = this.mergeStickySlots(metadata);
    if (!this.isProcessing)
      for (let i = 0; i < metadata.slots.length; i++) {
        const slot = metadata.slots[i];

        console.debug('processing slot', i, new Date().toISOString());

        await this.processSongSlot(slot);

        console.debug('slot processed', i, new Date().toISOString());

        if (i === 0) {
          await this.NotifyStatus(metadata, true);
        }
      }
    await this.NotifyStatus(metadata);
  }

  mergeStickySlots(metadata: MoodMetadata) {
    console.debug('looking for active slot.');
    const newSlots = [];
    let activeSlot = metadata.slots[0];

    for (let i = 1; i < metadata.slots.length; i++) {
      const slot = metadata.slots[i];

      if (slot.startTime === activeSlot.endTime) {
        activeSlot.songs = activeSlot.songs.concat(slot.songs);
        activeSlot.endTime = slot.endTime;
      } else {
        newSlots.push(activeSlot);
        activeSlot = slot;
      }
    }

    newSlots.push(activeSlot); // Add the last activeSlot
    metadata.slots = newSlots;
    return metadata;
  }

  private async processSongSlot(slot: Slot) {
    const downloadBatchSize = 5; // Define the size for the initial batch of songs

    const downloadSong = async (slotSongIndex: number) => {
      try {
        this.isProcessing = true;
        let songBlob: any;
        try {
          const songId = slot.songs[slotSongIndex].id.toString();
          songBlob = await this.storageService.getAudio(songId);
        } catch (e: any) {
          console.warn('cant get song from storage', e.message);
        }

        if (songBlob) {
          const audioUrl = URL.createObjectURL(songBlob);
          slot.songs[slotSongIndex].audioURL = audioUrl;
          console.debug(
            'loading music from storage',
            slotSongIndex + 1,
            slot.songs[slotSongIndex].id
          );
        } else {
          const blob = await this.moodPlayerService.getSongById(
            slot.songs[slotSongIndex].id
          );
          const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
          const audioUrl = URL.createObjectURL(audioBlob);
          slot.songs[slotSongIndex].audioURL = audioUrl;
          await this.storageService.storeAudio(
            slot.songs[slotSongIndex].id.toString(),
            slot.songs[slotSongIndex].endDatetime,
            audioBlob
          );
          console.debug(
            'loading music from the API',
            slotSongIndex + 1,
            slot.songs[slotSongIndex].id
          );
        }
      } catch (e: any) {
        console.error('error processing song', e.message);
      } finally {
        this.isProcessing = false;
      }
    };

    // Process the first batch of songs synchronously
    for (let i = 0; i < Math.min(downloadBatchSize, slot.songs.length); i++) {
      await downloadSong(i);
      this.InitialThresholdDownloaded.next(false);

    }

    // Signal that initial batch is ready for playback
    this.InitialThresholdDownloaded.next(true);

    // Introduce a delay to allow the application to react to the playback start signal
    await new Promise((resolve) => setTimeout(resolve, 500)); // 500 ms delay

    // Process the remaining songs sequentially in the background
    const processRemainingSongsSequentially = async () => {
      for (let i = downloadBatchSize; i < slot.songs.length; i++) {
        this.percentageToCompleteAllSongs.next(
          ((i + 1) / slot.songs.length) * 100
        );

        await downloadSong(i);
      }
      // Signal that all songs have been processed after the last song is downloaded
      this.slotProcessed$.next(slot);
    };

    processRemainingSongsSequentially().catch((e) =>
      console.error('Error processing remaining songs:', e)
    );

    // Do not wait for the background process to complete before moving on
    // The function ends here, allowing the application to proceed
  }

  private findActiveSlot(metadata: MoodMetadata): Slot | undefined {
    console.debug('looking for active slot.');
    const currentTime = new Date();
    const activeSlot = metadata.slots.find((slot) => {
      return (
        new Date(slot.startTime) <= currentTime &&
        new Date(slot.endTime) > currentTime
      );
    });

    if (!activeSlot) return undefined;

    const nextSlotIndex = metadata.slots.indexOf(activeSlot) + 1;
    if (metadata.slots.length <= nextSlotIndex) return activeSlot;

    const nextSlot = metadata.slots[nextSlotIndex];
    if (!nextSlot) return activeSlot;

    if (activeSlot.endTime === nextSlot.startTime) {
      // Create a new slot object if immutability is a concern
      return {
        ...activeSlot,
        songs: activeSlot.songs.concat(nextSlot.songs),
        endTime: nextSlot.endTime,
      };
    }

    return activeSlot;
  }

  private findFutureSlot(metadata: MoodMetadata): Slot | undefined {
    console.debug('looking for future slot.');
    return metadata.slots.find((slot: Slot) => {
      return (
        new Date(slot.startTime) > new Date() &&
        new Date(slot.endTime) > new Date()
      );
    });
  }

  private subscribeToAllDownloadsCompleted() {
    this.downloadCompleted.subscribe((completed) => {
      this.remainingSongsToDownload = this.remainingSongsToDownload - 1;
      this.DownloadingCountDown.next(this.remainingSongsToDownload);
      if (this.remainingSongsToDownload === 0) {
        this.allDownloadsCompleted.next(true);
        console.debug('All Downloads Completed');
      }
    });
  }

  private notify(status: PlaylistStatus, timeStamp: Date) {
    console.debug('Notify---> ', new Date().toISOString());

    this.PlaylistStatus.next({
      startingTime: timeStamp,
      status: status,
    });
  }

  async checkForSong(slot: Slot, metadata: MoodMetadata): Promise<boolean> {
    console.debug('Checking for song to play');
    const songToPlay = this.findSongIndexToPlay(slot);
    console.debug('song index to play', songToPlay);
    if (songToPlay > -1) {
      console.debug('startPlayingAuto$' + JSON.stringify({slot}));

      const activeSlot = this.findActiveSlot(metadata);
      if (!activeSlot) return false;

      const downloadBatchSize = 5; // Download the first 5 songs synchronously

      // Process the initial batch of songs synchronously
      for (
        let i = 0;
        i < Math.min(downloadBatchSize, activeSlot.songs.length);
        i++
      ) {
        await this.DownloadSong(activeSlot.songs[i]);
      }

      // Notify that the initial batch is ready for playback
      // Assuming there's some mechanism like this.InitialThresholdDownloaded.next(true); to notify

      // Process the remaining songs in the background, one at a time
      const processRemainingSongsInBackground = async () => {
        for (let i = downloadBatchSize; i < activeSlot.songs.length; i++) {
          // Wait for each song to be processed before continuing to the next
          await this.DownloadSong(activeSlot.songs[i]).catch((e) =>
            console.error('Error processing song in background:', e)
          );
        }
      };

      processRemainingSongsInBackground(); // Start this process without using 'await' to not block

      this.setSlotSongs([...activeSlot.songs]);

      await this.playSong(songToPlay);
      console.debug('song to play', songToPlay);
      console.debug(this.playlist);
      this.PlayingSong.next(this.playlist[songToPlay]);
      this.notify(PlaylistStatus.playing, new Date(slot.startTime));

      console.debug('clearing checkForSongToPlayTimer timer');
      clearInterval(this.checkForSongToPlayTimer);
      return true;
    }
    return false;
  }

  async DownloadSong(song: any): Promise<void> {
    const audioBlob = await this.storageService.getAudio(song.id.toString());
    if (audioBlob) {
      song.audioURL = URL.createObjectURL(audioBlob);
    } else if (!this.isProcessing) {
      try {
        const blob = await this.moodPlayerService.getSongById(song.id);
        const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
        const audioUrl = URL.createObjectURL(audioBlob);
        song.audioURL = audioUrl;
        await this.storageService.storeAudio(
          song.id.toString(),
          song.endDatetime,
          audioBlob
        );
        console.debug('loading music from the API', song.id);
      } catch (e: any) {
        console.error('error downloading song', e.message);
      }
    }
  }

  bundleActions: (() => Promise<any>)[] = [];

  private findSongIndexToPlay(activeSlot: Slot): number {
    // if (this.isMixing) return -1;
    const currentTime = new Date();

    for (let i = 0; i < activeSlot.songs.length; i++) {
      const song = activeSlot.songs[i];
      const songStartTime = new Date(song.startDatetime);
      const songEndTime = new Date(song.endDatetime);
      if (currentTime >= songStartTime && currentTime <= songEndTime) {
        return i;
      }
    }

    return -1;
  }

  private lookForPlayList(): void {
    const metadata = this.metadata;

    if (metadata) {
      const slot = this.findActiveSlot(metadata);
      if (!slot) return;
      this.startPlayProcess(metadata, slot)
        .then(() => {
          console.debug('***Starting Player ', this.activePlayer);
        })
        .catch((e) => {
          console.error('error initializing', e.message);
        });
    } else {
      this.getMetadata?.subscribe((metadata) => {
        const slot = this.findActiveSlot(metadata);
        if (!slot) return;

        this.startPlayProcess(metadata, slot)
          .then(() => {
            console.debug('***Starting Player ', this.activePlayer);
          })
          .catch((e) => {
            console.error('error initializing', e.message);
          });
      });
    }
  }

  // *********************************************************************************************************************
  // *********************************************************************************************************************
  // End of playing logic
  // *********************************************************************************************************************
  // *********************************************************************************************************************
  public async playSong(index: number) {
    //set the currentIndex to the chosen song -1 if it's not the 1st song in the playlistSongs.
    //The currentIndex is used to determine which song is playing, and which song is next.
    // In the event that the song is not the first song in the list, it means that we will always set the current index to the previous song as to allow the MIX to happen.
    this.stopMonitor();
    this.Stop();

    if (index > 0) this.setCurrentIndex(index - 1);
    else this.setCurrentIndex(index);

    if (index === 0) {
      this.songA = this.playlist[index];
      this.songB = this.playlist[index + 1];
    } else if (index > this.playlist.length - 1) {
      this.songA = this.playlist[index];
      this.songB = null;
    } else {
      this.songA = this.playlist[index - 1];
      this.songB = this.playlist[index];
    }
    this.setSongPlayingA(this.songA?.id);
    await this.initializeSongs(index);
  }

  private async mixMusic() {
    if (this.isProcessingNextSong) {
      return;
    }
    const waveSurferInstance = (this as any)[`waveSurferService` + this.activePlayer];
    const nextWaveSurferInstance = this.activePlayer === 'A' ? this.waveSurferServiceB : this.waveSurferServiceA;

    const song = (this as any)['song' + this.activePlayer] as Song;
    const nextSong = (this as any)['song' + (this.activePlayer === 'A' ? 'B' : 'A')] as Song;

    const currentTime = (this as any)[`currentTime` + this.activePlayer];
    this.CurrentTime.next(currentTime);

    if (!song) {
      this.Stop();
      return;
    }

    if (!waveSurferInstance.isPlaying() && !this.playedOnce) {
      return;
    }
    this.playedOnce = true;

    const fadeOutEnabled = song.fadeOut != 0;
    const fadeInEnabled = nextSong?.fadeIn != 0;

    if (this.PlayingSong.value?.id !== song.id) {
      this.PlayingSong.next(song);
    }
    // StopTime is when the current active song should Stop.
    // This is calculated in 3 different scenarios:
    // 1) If the FadeOut is before the MixTime, it means that the Mixtime is the StopTime
    // 2) If the FadeOut is after the MixTime, it means that the FadeOut is the StopTime
    // 3) If there is No Fadeout, it means that the StopTime is the end of the song.

    if (!this.isMixing && this.stopTime === 0) {
      if (song.fadeOut === 0) {
        this.stopTime = song.duration;
      } else {
        this.stopTime = song.fadeOut < 0 ? song.mixTime : +song.mixTime + +song.fadeOut;
      }
    }
    if (currentTime >= this.stopTime || !waveSurferInstance.isPlaying()) {

      waveSurferInstance?.stop();

      this.stopTime = 0;
      this.isMixing = false;
      this.isFadingOut = false;
      this.playedOnce = false;

      this.activePlayer === 'A'
        ? this.setSongPlayingA(null)
        : this.setSongPlayingB(null);

      // If the current song is the last song in the playlist, we need to stop the player.

      if (this.getCurrentIndex() + 1 >= this.playlist.length) { //If this is the last song in the playlist
        this.Stop();
        this.lookForPlayList();
        return;
      } else {

        // If a song has stopped on for Example, PlayerA, then we swap the context to PlayerB, and set the next song to PlayerA.
        if (this.getCurrentIndex() + 2 >= this.playlist.length) {
          (this as any)['song' + this.activePlayer] = null; // Reset the song to null, as we are done with the playlistSongs.
          this.stopTime = 0;
          this.isMixing = false;
          this.isFadingOut = false;
          this.playedOnce = false;
          this.activePlayer = this.activePlayer === 'A' ? 'B' : 'A';
          this.setCurrentIndex(this.getCurrentIndex() + 1);
          return;
        }

        // Due to wrong configuration, it's possible that the next song starts before the mixtime is evert hit, and the current song ends.
        // We must handle this case by starting the next song regardless, as the current song is being unloaded!
        // We also have the case that the fadeout has happened, and stoptime is hit, but next song not started yet, now is the time to start it.
        if (!nextWaveSurferInstance.isPlaying()) {
          nextWaveSurferInstance?.seekTo(+nextSong.startTime);
          //If the Fade-in is enabled, we need to have fade in behavior, otherwise, we don't fade, and just set the to the tracks volume settings (default 85%)
          if (fadeInEnabled) {
            nextWaveSurferInstance.fadeIn(
              nextSong.fadeIn * 1000,
              nextSong.volume
            );
          } else {
            if (this.activePlayer === 'A') {
              console.warn('SETTING VOLUME FOR PLAYER A -> ACTIVE PLAYER ' + this.activePlayer)
              this.setVolumeA(+nextSong.volume * this.playerVolume);
            } else {
              console.warn('SETTING VOLUME FOR PLAYER B -> ACTIVE PLAYER ' + this.activePlayer)
              this.setVolumeB(+nextSong.volume * this.playerVolume);
            }
          }
          const songPlayingFunction =
            this.activePlayer === 'A' ? 'setSongPlayingB' : 'setSongPlayingA';
          (this as any)[songPlayingFunction](nextSong?.id);
          nextWaveSurferInstance.play();

        }

        // Enqueue the next stop in the example above in PlayerA (Since we have not yet swapped the context to PlayerB, however, PlayerB would be playing at this point)
        try {
          this.isProcessingNextSong = true;

          await this.enqueueNextSong(
            waveSurferInstance,
            (wavesurfer) =>
              ((this as any)[`waveSurferService` + this.activePlayer] =
                wavesurfer)
          );
        } catch (e: any) {
          console.error('error enqueueing next song', e.message);
        } finally {
          this.isProcessingNextSong = false;
        }
        //Directly after enqueuing the next song, we swap the context to PlayerB, which will allow the next iteration of this MixMusic function to play the next song on PlayerB.
        // This will put the context on SongB to set the variables appropriately for SongB, hence we reset the stopTime, isMixing, isFadingOut so they are re-initialized for SongB.

        this.activePlayer = this.activePlayer === 'A' ? 'B' : 'A';
        this.setCurrentIndex(this.getCurrentIndex() + 1);

        // After resetting everything, we should directly stop the current loop, as the next loop will be with the new context.
        return;
      }
    }

    // The Trigger time for Fading Out a song is calculated in 2 different scenarios:
    // 1) If the FadeOut is a Negative Number, it means that it's before the mix time. The Fadeout should be triggered at fadeOutTime which is the mixtime - the absolute value of the fadeout duration.
    // 2) If the Fadeout is a Positive Number, it means that it's after the mix time. The Fadeout should be triggered at the MixTime.
    const triggerFadeOutTime =
      song.fadeOut < 0 ? song.mixTime - Math.abs(+song.fadeOut) : song.mixTime;

    // If the Fade Out checkbox is selected, we need to trigger the fadeout at the appropriate time which we calculated as the triggerFadeOutTime.
    // If the Fade Out is not enabled, the song should play until the end of the Track, which is controlled by the StopTime. and stopTime Routine.
    if (fadeOutEnabled) {
      if (currentTime >= triggerFadeOutTime && !this.isFadingOut) {
        this.isFadingOut = true;
        const fade_out_duration = Math.abs(+song.fadeOut) * 1000;
        waveSurferInstance.fadeOut(fade_out_duration, song.volume);

      }
    }

    // If the music is playing, and we have not yet started mixing the two songs, we check if the current song time hits the mix time target, and if so, we need to start mixing.

    if (song.mixTime <= 0) {
      song.mixTime = song.duration;
      console.debug('setting mix time to song duration', song.mixTime);
    }

    if ((currentTime >= song.mixTime || !waveSurferInstance.isPlaying()) && !this.isMixing) {

      this.isMixing = true;
      if (!nextSong) {
        this.Stop();
        return;
      }
      nextWaveSurferInstance?.seekTo(+nextSong.startTime);
      //If the Fade-in is enabled, we need to have fade in behavior, otherwise, we don't fade, and just set the to the tracks volume settings (default 85%)
      if (fadeInEnabled) {
        nextWaveSurferInstance.fadeIn(nextSong.fadeIn * 1000, nextSong.volume);
      } else {
        this.setVolumeB(+nextSong.volume * this.playerVolume);
      }
      const songPlayingFunction = this.activePlayer === 'A' ? 'setSongPlayingB' : 'setSongPlayingA';

      (this as any)[songPlayingFunction](+nextSong?.id);
      nextWaveSurferInstance.play();
    }
  }

  private async enqueueNextSong(
    waveSurferServiceInstance: WaveSurferService,
    out: (waveSurferInstance: WaveSurferService) => void
  ) {
    if (this.getCurrentIndex() + 2 > this.playlist.length) return;

    const song = this.playlist[this.getCurrentIndex() + 2];
    if (!song) return;

    if (song.mixTime <= 0) {
      song.mixTime = song.duration;
      console.debug('setting mix time to song duration', song.mixTime);
    }

    if (song.mixTime > song.duration) {
      song.mixTime = song.duration;
    }


    // songA is always Played on PlayerA and songB is always Played on PlayerB.
    // If the howerInstance is that of playerA in this function, it means that the playerA has already Stopped, and we are ready to load the next song into it.
    (this as any)['song' + this.activePlayer] = song;

    let audioStreamUrl = song.audioURL;
    if (!audioStreamUrl || audioStreamUrl === '') {
      console.warn(`No Audio Stream for song ${JSON.stringify(song)}`);
      console.debug('loading music from the API', song.id);
      const audioURL = await this.downloadAndStoreSong(song);
      song.audioURL = audioURL;
      audioStreamUrl = audioURL;
    }

    waveSurferServiceInstance.getWaveSurfer(
      '#waveform' + this.activePlayer,
      audioStreamUrl,
      this.dummyPeaks,
      song.duration
    );

    waveSurferServiceInstance.seekTo(song.startTime);
    waveSurferServiceInstance.setVolume(song.volume);
    out(waveSurferServiceInstance);
  }

  private async downloadAndStoreSong(song: Song) {
    let audioUrl = '';
    try {
      const blob = await this.moodPlayerService.getSongById(song.id);
      const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
      audioUrl = URL.createObjectURL(audioBlob);
      song.audioURL = audioUrl;

      await this.storageService.storeAudio(
        song.id.toString(),
        song.endDatetime,
        audioBlob
      );
      console.debug('loading music from the API', song.id);
    } catch (e: any) {
      console.error('error downloading song', e.message);
    }
    console.warn('music loaded audio url', audioUrl);

    return audioUrl;
  }

  private setVolumeA(volume: number) {
    console.debug('setVolume', volume);
    if (volume >= 0) {
      this.waveSurferServiceA?.setVolume(volume);
    }
  }

  private setVolumeB(volume: number) {
    if (volume >= 0) {
      this.waveSurferServiceB?.setVolume(volume);
    }
  }

  // Generate a function called Stop which can be called from the parent component to stop the player

  Stop() {
    this.stopMonitor();
    clearInterval(this.checkForSongToPlayTimer);
    this.songA = null;
    this.songB = null;
    this.waveSurferServiceA?.stop();
    this.waveSurferServiceB?.stop();
    this.setSongPlayingA(null);
    this.setSongPlayingB(null);
  }

  public NotifyToStop() {
    this.PlaylistStatus.next({
      startingTime: new Date(),
      status: PlaylistStatus.stopped,
    });
  }

  public SetUIVolume(volume: number) {
    this.playerVolume = volume;
    const songAVolume = this.songA?.volume ?? 1;
    const songBVolume = this.songB?.volume ?? 1;

    this.setVolumeA(songAVolume * this.playerVolume);
    this.setVolumeB(songAVolume * this.playerVolume);
  }

  public SongAToPlay$ = new Subject<Song>();
  public SongBToPlay$ = new Subject<Song>();

  private Play() {
    if (this.activePlayer === 'A' && this.songA) {
      this.setVolumeA(this.songA.volume * this.playerVolume);
      this.waveSurferServiceA.play();
      this.SongAToPlay$.next(this.songA);
    } else if (this.activePlayer === 'B' && this.songB) {
      this.setVolumeB(this.songB.volume * this.playerVolume);
      this.waveSurferServiceB.play();
      this.SongBToPlay$.next(this.songB);
    }
  }

  ngOnDestroy() {
    this.stopMonitor();
    // this.waveSurferServiceA.destroy();
    // this.waveSurferServiceB.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
