// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-side-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customIconSize {
  height: 160px;
  width: 160px;
  margin-right: 20px;
}

.container {
  background-color: whitesmoke;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;;AAIA;EACE,aAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAGA;EACE,4BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF","sourcesContent":[":host {\n}\n:host {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.auth-side-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.customIconSize {\n  height: 160px;\n  width: 160px;\n  margin-right: 20px;\n}\n.container {\n  background-color: whitesmoke;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
