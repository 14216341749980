import { Component } from '@angular/core';
import { PlaylistPlayerService } from '../core/services/playlist-player.service';
import {PlaylistPlayerV2Service} from "../core/services/playlist-player-v2.service";

@Component({
  selector: 'app-stop-music-popup',
  templateUrl: './stop-music-popup.component.html',
  styleUrls: ['./stop-music-popup.component.scss'],
})
export class StopMusicPopupComponent {
  constructor(private playlistPlayerV2Service: PlaylistPlayerV2Service) {}
  stop() {
    this.playlistPlayerV2Service.Stop();
    this.playlistPlayerV2Service.NotifyToStop();
  }
}
