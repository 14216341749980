/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-misused-promises */
import {Injectable} from '@angular/core';
import {StorageService} from "./storage.service";
import {MoodPlayerService} from "./mood-player.service";
import {BehaviorSubject, lastValueFrom, Observable, Subject, takeUntil, throttleTime} from "rxjs";
import {Song} from "../interfaces/Song";
import {Slot} from "../interfaces/Slot";
import {MoodMetadata} from "../interfaces/MoodMetadata";
import {IPlaylistStatus, PlaylistStatus} from "../interfaces/IPlaylistStatus";
import WaveSurfer from "wavesurfer.js";


@Injectable({
  providedIn: 'root'
})
export class PlaylistPlayerV2Service {
  public CurrentTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public ActiveSong: Subject<Song> = new Subject<Song>();
  public allDownloadsCompleted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public DownloadingCountDown: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  public StartPlayingSlot: BehaviorSubject<Song | null> =
    new BehaviorSubject<Song | null>(null);
  public PlaylistStatus: Subject<IPlaylistStatus | null> =
    new Subject<IPlaylistStatus | null>();
  public PrefetchNextSongAudio: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public FetchingNextSongAudio: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  private remainingSongsToDownload = 0;
  private downloadCompleted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private checkForSongToPlayTimer: NodeJS.Timer = {} as NodeJS.Timer;
  private checkForObsoleteSongsTimer: NodeJS.Timer = {} as NodeJS.Timer;
  private getMetadata: Observable<MoodMetadata> | undefined;
  public InitialThresholdDownloaded = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();
  private playlist: Song[] = [];
  private playlistSongs = new Subject<Song[]>();
  private isProcessing = false;
  metadata: MoodMetadata = {} as MoodMetadata;
  public slotProcessed$: BehaviorSubject<Slot | undefined> = new BehaviorSubject<
    Slot | undefined
  >(undefined);
  public percentageToCompleteAllSongs: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private waveSurfer1: WaveSurfer = {} as WaveSurfer;
  private waveSurfer2: WaveSurfer = {} as WaveSurfer;
  private mixingTimeUpdateWaveSurfer1: Subject<Song> = new Subject();
  private mixingTimeUpdateWaveSurfer2: Subject<Song> = new Subject();
  private fadeOutTimeUpdateWaveSurfer1: Subject<Song> = new Subject();
  private fadeOutTimeUpdateWaveSurfer2: Subject<Song> = new Subject();
  private fadeInTimeUpdateWaveSurfer1: Subject<Song> = new Subject();
  private fadeInTimeUpdateWaveSurfer2: Subject<Song> = new Subject();
  private foundSong = false;
  private epsilon = 2.5;

  constructor(
    private storageService: StorageService,
    private moodApiPlayerService: MoodPlayerService,
  ) {
    this.playlistSongs.subscribe((songs) => {
      this.playlist = [...songs];
    });
    this.slotProcessed$.subscribe((slot) => {
      console.log('assigning audio urls');
      for (let i = 0; i < this.playlist.length; i++) {
        if (
          this.playlist[i].audioURL === undefined ||
          this.playlist[i].audioURL === ''
        ) {
          const processedSong = slot?.songs.find(
            (s) => s.id === this.playlist[i].id
          );
          if (processedSong) {
            this.playlist[i].audioURL = processedSong.audioURL;
          } else {
            console.warn('not found a processed audio urls');
          }
        }
      }
    });
    this.StartPlayingSlot.subscribe(async (song) => {
      if (song) await this.WaveSurferStartPlaying(song, this.waveSurfer1);

    });
  }


  public async Start(getMetadata$: Observable<MoodMetadata>, waveSurfer1: WaveSurfer, waveSurfer2: WaveSurfer) {
    console.log('STARTED');

    this.waveSurfer1 = waveSurfer1;
    this.waveSurfer2 = waveSurfer2;
    this.getMetadata = getMetadata$;
    this.mixingTimeUpdateWaveSurfer1 = new Subject();
    this.mixingTimeUpdateWaveSurfer2 = new Subject();
    this.fadeOutTimeUpdateWaveSurfer1 = new Subject();
    this.fadeOutTimeUpdateWaveSurfer2 = new Subject();
    this.fadeInTimeUpdateWaveSurfer1 = new Subject();
    this.fadeInTimeUpdateWaveSurfer2 = new Subject();


    this.fadeInTimeUpdateWaveSurfer1.subscribe((song) => {
      if (!song) {
        console.error('no song for fade in 1');
        return;
      }
      if (song.isFadingIn === undefined) {
        song.isFadingIn = false;
      }

      const unSubscribe1 = waveSurfer1?.on('audioprocess', (currentTime) => {
          if (!song) {
            console.error('no song for fade in 1');
            return;
          }
          if (song.fadeIn === 0) {
            unSubscribe1();
            console.log('no fade in 2');
            return;
          }
          console.log(`fadeIn time update 1 ${currentTime} - ${song.mixTime} - ${song.title} - isMixing- ${String(song.isMixing)} - isFadingIn- ${String(song.isFadingIn)} - ** Volume - ${waveSurfer1.getVolume()}`);

          if (song.isFadingIn === false && Math.abs(song.fadeIn) > 0 && currentTime >= (song.startTime) && currentTime < song.mixTime) {
            song.isFadingIn = true;
            unSubscribe1();
            console.log('Fade in 1 Started');
            // Start fade-out process for this WaveSurfer instance
            console.log('WAVE SURFER 1: Fade in before mix time Started');

            this.startFadeInProcess(waveSurfer1, song);
          }
        }
      );
    });
    this.fadeInTimeUpdateWaveSurfer2.subscribe((song) => {
      if (!song) {
        console.error('no song for fade in 2');
        return;
      }
      if (song.isFadingIn === undefined) {
        song.isFadingIn = false;
      }

      const unSubscribe1 = waveSurfer2?.on('audioprocess', (currentTime) => {
          if (!song) {
            console.error('no song for fade in 2');
            return;
          }
          if (song.fadeIn === 0) {
            unSubscribe1();
            console.log('no fade in 2');
            return;
          }
          console.log(`fadeIn time update 2 ${currentTime} - ${song.mixTime} - ${song.title} - isMixing- ${String(song.isMixing)} - isFadingIn- ${String(song.isFadingIn)} - ** Volume - ${waveSurfer2.getVolume()}`);
          if (song.isFadingIn === false && Math.abs(song.fadeIn) > 0 && currentTime >= (song.startTime) && currentTime < song.mixTime) {
            song.isFadingIn = true;
            unSubscribe1();

            console.log('Fade in 2 Started');
            // Start fade-out process for this WaveSurfer instance
            console.log('WAVE SURFER 1: Fade in before mix time Started');

            this.startFadeInProcess(waveSurfer2, song);
          }
        }
      );
    });


    this.fadeOutTimeUpdateWaveSurfer1.subscribe((song) => {
      if (!song) {
        console.error('no song for fadeout 1');
        return;
      }

      if (song.isFadingOut === undefined) {
        song.isFadingOut = false;
      }
      if (song.mixTime === 0) {
        song.mixTime = song.duration;
      }
      if (song.fadeOut > 0) {
        song.fadeOutStartTime = song.mixTime;
      } else if (song.fadeOut < 0) {
        song.fadeOutStartTime = song.mixTime + song.fadeOut; // in this case fadeout is negative that is why we are adding instead of subtracting
      }
      const unSubscribe1 = waveSurfer1?.on('audioprocess', (currentTime) => {
          if (!song) {
            console.error('no song for fadeout 1');
            return;
          }
          if (song.fadeOut === 0) {
            unSubscribe1()
            console.log('no fade out 2');
            return;
          }
          console.log(`fadeout time update 1 ${currentTime} - ${song.mixTime} - ${song.title} - isMixing- ${String(song.isMixing)} - isFadingOut- ${String(song.isFadingOut)} - ** Volume - ${waveSurfer1.getVolume()}`);

          if (song.isFadingOut === false && Math.abs(song.fadeOut) > 0 && currentTime >= song.fadeOutStartTime && currentTime < song.endTime) {
            song.isFadingOut = true;
            unSubscribe1();

            console.log(`WAVE SURFER 1: Fade Out ** ${song.fadeOut < 0 ? 'before' : 'after'} ** mix time Started`);
            this.startFadeOutProcess(waveSurfer1, song);
          }
        }
      );
    });
    this.fadeOutTimeUpdateWaveSurfer2.subscribe((song) => {
      if (!song) {
        console.error('no song for fadeout 2');
        return;
      }

      if (song.isFadingOut === undefined) {
        song.isFadingOut = false;
      }
      if (song.mixTime === 0) {
        song.mixTime = song.duration;
      }
      if (song.fadeOut > 0) {
        song.fadeOutStartTime = song.mixTime;
      } else if (song.fadeOut < 0) {
        song.fadeOutStartTime = song.mixTime + song.fadeOut; // in this case fadeout is negative that is why we are adding instead of subtracting
      }
      const unSubscribe1 = waveSurfer2?.on('audioprocess', (currentTime) => {
          if (!song) {
            console.error('no song for fadeout 2');
            return;
          }
          if (song.fadeOut === 0) {
            unSubscribe1()
            console.log('no fade out 2');
            return;
          }
          console.log(`fadeout time update 2 ${currentTime} - ${song.mixTime} - ${song.title} - isMixing- ${String(song.isMixing)} - isFadingOut- ${String(song.isFadingOut)} - ** Volume - ${waveSurfer2.getVolume()}`);
          if (song.isFadingOut === false && Math.abs(song.fadeOut) > 0 && currentTime >= song.fadeOutStartTime && currentTime < song.endTime) {
            song.isFadingOut = true;
            unSubscribe1();

            // Start fade-out process for this WaveSurfer instance
            console.log('WAVE SURFER 2: Fade Out before mix time Started');

            this.startFadeOutProcess(waveSurfer2, song);
          }
        }
      );
    });


    // eslint-disable-next-line @typescript-eslint/no-misused-promises


    this.mixingTimeUpdateWaveSurfer1.subscribe((song) => {
      if (!song) {
        console.error('no song for mixing 1');
        return;
      }
      if (song.isMixing === undefined) {
        song.isMixing = false;
      }
      if (song.mixTime === 0) {
        song.mixTime = song.duration;
      }
      if (song.fadeOut <= 0) {
        song.endTime = song.mixTime;
      } else {
        song.endTime = song.mixTime + song.fadeOut;
      }
      if (this.playlist.indexOf(song) + 1 < this.playlist.length) {
        this.prefetchNextSongAudio(song).then(() => {
        })
          .catch((error) => {
            console.error('Error prefetching the next song:', error);
          });
      }
      const unSubscribe1 = waveSurfer1?.on('audioprocess', async (currentTime) => {
        if (!song) {
          console.error('no song for mixing 1');
          return;
        }

        console.log(`mixing time update 1 ${currentTime} - ${song.mixTime} - ${song.title} - isMixing- ${String(song.isMixing)}`);
        if (currentTime >= song.mixTime - this.epsilon && song.isMixing == false) {
          song.isMixing = true;
          unSubscribe1();
          console.log('Mixing 1 Started ', song.endTime, currentTime, song.title);
          if (this.playlist.indexOf(song) + 1 == this.playlist.length) {
            console.log('End of Playlist 1');
            this.mixingTimeUpdateWaveSurfer1.unsubscribe();
            this.mixingTimeUpdateWaveSurfer2.unsubscribe();
            console.log('pausing all 1')
            this.Stop();
            this.NotifyToStop();
            await this.Start(this.getMetadata!, this.waveSurfer1, this.waveSurfer2);
            return;
          }
          const nextSong = this.playlist[this.playlist.indexOf(song) + 1];
          let nextAudioStreamUrl = nextSong.audioURL;
          if (!nextAudioStreamUrl || nextAudioStreamUrl === '') {
            console.log('loading music from the API', nextSong.id);
            this.FetchingNextSongAudio.next(true);

            const audioURL = await this.downloadAndStoreSong(nextSong);
            this.FetchingNextSongAudio.next(false);
            if (audioURL === '') {
              // get the next song from the this.playlist after the index of nextsong that has audio url not emptu
              let nextSongIndex = this.playlist.indexOf(nextSong) + 1;
              while (nextSongIndex < this.playlist.length) {
                const nextSong = this.playlist[nextSongIndex];
                if (nextSong.audioURL && nextSong.audioURL !== '') {
                  nextAudioStreamUrl = nextSong.audioURL;
                  break;
                }
                nextSongIndex++;
              }
            } else {
              nextSong.audioURL = audioURL;
              nextAudioStreamUrl = audioURL;
            }
          }
          waveSurfer2?.setOptions({
            waveColor: 'white',
            backend: 'WebAudio',
            cursorColor: 'yellow',
            barHeight: 0.5,
            height: 70,
            url: nextAudioStreamUrl,
            peaks: [[0]],
            duration: nextSong.duration,
            fetchParams: {
              headers: {
                Authorization: 'Bearer ' + this.storageService.getToken(),
              },
            },
          });
          await waveSurfer2?.load(nextAudioStreamUrl, [[0]]).catch(() => {
            console.log('Error loading audio');
          })

          console.log('seeking to 2', nextSong.startTime, nextSong.title);
          waveSurfer2?.setTime(nextSong.startTime);
          if (nextSong.fadeIn > 0) {
            waveSurfer2?.setVolume(0);
          } else {
            waveSurfer2?.setVolume(nextSong.volume);
          }

          const timeDifference = song.mixTime - currentTime;
          if (timeDifference > 0) {
            await this.delay(timeDifference * 1000); // Convert seconds to milliseconds
          }

          this.mixingTimeUpdateWaveSurfer2.next(nextSong);
          this.fadeOutTimeUpdateWaveSurfer2.next(nextSong);
          this.fadeInTimeUpdateWaveSurfer2.next(nextSong);
          await waveSurfer2?.play();
          this.ActiveSong.next(nextSong)
        } else {
          this.CurrentTime.next((100 * currentTime) / song.endTime);
        }

      });

    });
    this.mixingTimeUpdateWaveSurfer2.subscribe((song) => {
      if (!song) {
        console.error('no song for mixing 2');
        return;
      }
      if (song.isMixing === undefined) {
        song.isMixing = false;
      }
      if (song.mixTime === 0) {
        song.mixTime = song.duration;
      }
      if (song.fadeOut <= 0) {
        song.endTime = song.mixTime;
      } else {
        song.endTime = song.mixTime + song.fadeOut;
      }
      if (this.playlist.indexOf(song) + 1 < this.playlist.length) {
        this.prefetchNextSongAudio(song).then(() => {
        })
          .catch((error) => {
            console.error('Error prefetching the next song:', error);
          });
      }
      const unSubscribe2 = waveSurfer2?.on('audioprocess', async (currentTime) => {
        if (!song) {
          console.error('no song for mixing 2');
          return;
        }


        console.log(`mixing time update 2 ${currentTime} - ${song.mixTime} - ${song.title} - isMixing- ${String(song.isMixing)}`);

        if (currentTime >= song.mixTime - this.epsilon && song.isMixing == false) {
          song.isMixing = true;
          unSubscribe2();
          console.log('Mixing 2 Started ', song.endTime, currentTime, song.title);
          if (this.playlist.indexOf(song) + 1 == this.playlist.length) {
            console.log('End of Playlist 2');
            this.mixingTimeUpdateWaveSurfer1.unsubscribe();
            this.mixingTimeUpdateWaveSurfer2.unsubscribe();
            console.log('pausing all 2')
            this.Stop();
            this.NotifyToStop();
            console.log('finished playlist');
            await this.Start(this.getMetadata!, this.waveSurfer1, this.waveSurfer2);
            return;
          }

          const nextSong = this.playlist[this.playlist.indexOf(song) + 1];
          let nextAudioStreamUrl = nextSong.audioURL;
          if (!nextAudioStreamUrl || nextAudioStreamUrl === '') {
            console.log('loading music from the API', nextSong.id);
            this.FetchingNextSongAudio.next(true);
            const audioURL = await this.downloadAndStoreSong(nextSong);
            this.FetchingNextSongAudio.next(false);

            if (audioURL === '') {
              // get the next song from the this.playlist after the index of nextsong that has audio url not emptu
              let nextSongIndex = this.playlist.indexOf(nextSong) + 1;
              while (nextSongIndex < this.playlist.length) {
                const nextSong = this.playlist[nextSongIndex];
                if (nextSong.audioURL && nextSong.audioURL !== '') {
                  nextAudioStreamUrl = nextSong.audioURL;
                  break;
                }
                nextSongIndex++;
              }
            } else {
              nextSong.audioURL = audioURL;
              nextAudioStreamUrl = audioURL;
            }
          }
          waveSurfer1?.setOptions({
            waveColor: 'white',
            backend: 'WebAudio',
            cursorColor: 'yellow',
            barHeight: 0.5,
            height: 70,
            url: nextAudioStreamUrl,
            peaks: [[0]],
            duration: nextSong.duration,
            fetchParams: {
              headers: {
                Authorization: 'Bearer ' + this.storageService.getToken(),
              },
            },
          });

          await waveSurfer1?.load(nextAudioStreamUrl, [[0]]).catch(() => {
            console.log('Error loading audio');
          });
          console.log('seeking to 1', nextSong.startTime, nextSong.title);
          waveSurfer1?.setTime(nextSong.startTime);

          if (nextSong.fadeIn > 0) {
            waveSurfer1?.setVolume(0);
          } else {
            waveSurfer1?.setVolume(nextSong.volume);
          }
          const timeDifference = song.mixTime - currentTime;
          if (timeDifference > 0) {
            // If mixTime is in the future, delay by the calculated time difference
            console.warn(`Delaying by ${timeDifference} seconds`);
            await this.delay(timeDifference * 1000); // Convert seconds to milliseconds
          }

          this.mixingTimeUpdateWaveSurfer1.next(nextSong)
          this.fadeOutTimeUpdateWaveSurfer1.next(nextSong);
          this.fadeInTimeUpdateWaveSurfer1.next(nextSong);
          await waveSurfer1?.play();
          this.ActiveSong.next(nextSong)

        } else {
          this.CurrentTime.next((100 * currentTime) / song.endTime);
        }

      });
    });

    try {
      // Convert the Observable to a Promise and await its completion
      const metadata = await lastValueFrom(
        getMetadata$.pipe(takeUntil(this.destroy$)),
        {
          defaultValue: undefined, // Provide a default value in case the Observable completes without emitting any values
        }
      );
      if (metadata) this.storageService.setMetadata(metadata);
      console.log('load meta started');
      await this.loadMetaAndStart();
    } catch (e: any) {
      console.error('error getting metadata', e.message);
      await this.loadMetaAndStart();
    }
    console.log('load meta ended');
  }

  async prefetchNextSongAudio(song: Song) {
    const currentIndex = this.playlist.indexOf(song);
    if (currentIndex + 1 < this.playlist.length) {
      const nextSong = this.playlist[currentIndex + 1];
      const nextAudioStreamUrl = nextSong.audioURL;

      if (!nextAudioStreamUrl || nextAudioStreamUrl === '') {
        this.PrefetchNextSongAudio.next(true);
        console.log('Prefetching music from the API for the next song', nextSong.id);
        const audioURL = await this.downloadAndStoreSong(nextSong);
        if (audioURL === '') {
          // get the next song from the this.playlist after the index of nextsong that has audio url not emptu
          let nextSongIndex = this.playlist.indexOf(nextSong) + 1;
          while (nextSongIndex < this.playlist.length) {
            const nextSong = this.playlist[nextSongIndex];
            if (nextSong.audioURL && nextSong.audioURL !== '') {
              break;
            }
            nextSongIndex++;
          }
        } else {
          nextSong.audioURL = audioURL;
        }
        this.PrefetchNextSongAudio.next(false);
      }
    }
  }

  startFadeOutProcess(currentWaveSurfer: WaveSurfer, song: Song) {
    const fadeOutInterval = setInterval(() => {
      const updatedCurrentTime = currentWaveSurfer.getCurrentTime(); // Update currentTime within interval
      if (updatedCurrentTime >= song.endTime) {
        clearInterval(fadeOutInterval);
        song.isFadingOut = false;
        console.log('pausing', currentWaveSurfer.options.container)
        currentWaveSurfer.pause();

        return;
      }

      const volume = this.calculateVolumeAtTimeFadeOut(updatedCurrentTime, song.fadeOutStartTime, song.endTime, song.startTime, song.volume);
      currentWaveSurfer.setVolume(volume);

    }, 100);
  }

  startFadeInProcess(currentWaveSurfer: WaveSurfer, song: Song) {
    const fadeInInterval = setInterval(() => {
      const updatedCurrentTime = currentWaveSurfer.getCurrentTime(); // Update currentTime within interval

      // Check if the current time has reached or exceeded the end time for the fade-in
      if (updatedCurrentTime >= song.fadeIn) {
        clearInterval(fadeInInterval);
        song.isFadingIn = false;
        console.log('Fade-in complete', currentWaveSurfer.options.container);
        currentWaveSurfer.setVolume(song.volume); // Set the volume to maximum after fade-in completes

        return;
      }

      // Calculate the volume based on the fade-in time
      const volume = this.calculateVolumeAtTimeForFadeIn(updatedCurrentTime, song.fadeIn, song.startTime, song.volume);
      currentWaveSurfer.setVolume(volume);
    }, 100);
  }

  calculateVolumeAtTimeFadeOut(currentTime: number, fadeOutStartTime: number, endTime: number, songStartTime: number, songMaxVolume: number) {
    if (currentTime >= fadeOutStartTime && currentTime <= endTime) {
      const remainingTime = endTime - currentTime;
      const fadeDuration = endTime - fadeOutStartTime;

      const volume = (remainingTime / fadeDuration) * songMaxVolume;
      return Math.max(0, Math.min(songMaxVolume, volume));
    } else if (currentTime > endTime) {
      return 0;
    } else {
      return songMaxVolume;
    }
  }

  calculateVolumeAtTimeForFadeIn(currentTime: number, fadeInDuration: number, fadeInStartTime: number, songMaxVolume: number) {
    if (currentTime >= fadeInStartTime && currentTime <= fadeInStartTime + fadeInDuration) {
      const elapsedTime = currentTime - fadeInStartTime;
      const volume = (elapsedTime / fadeInDuration) * songMaxVolume;
      return Math.max(0, Math.min(songMaxVolume, volume));
    } else if (currentTime < fadeInStartTime) {
      return 0;
    } else {
      return songMaxVolume;
    }
  }

  private async WaveSurferStartPlaying(song: Song, waveSurfer1: WaveSurfer) {
    console.log('WaveSurferStartPlaying', song);
    let audioStreamUrl = song.audioURL;
    if (!audioStreamUrl || audioStreamUrl === '') {
      console.log('loading music from the API', song.id);
      let audioURL = await this.downloadAndStoreSong(song);
      if (audioURL === '') {
        // get the next song from the this.playlist after the index of nextsong that has audio url not emptu
        let nextSongIndex = this.playlist.indexOf(song) + 1;
        while (nextSongIndex < this.playlist.length) {
          const nextSong = this.playlist[nextSongIndex];
          if (nextSong.audioURL && nextSong.audioURL !== '') {
            audioURL = nextSong.audioURL;
            break;
          }
          nextSongIndex++;
        }
      }
      song.audioURL = audioURL;
      audioStreamUrl = audioURL;

    }
    waveSurfer1?.setOptions({
      waveColor: 'white',
      backend: 'WebAudio',
      cursorColor: 'yellow',
      barHeight: 0.5,
      height: 70,
      url: audioStreamUrl,
      peaks: [[0]],
      duration: song.duration,
      fetchParams: {
        headers: {
          Authorization: 'Bearer ' + this.storageService.getToken(),
        },
      },
    });
    await waveSurfer1?.load(audioStreamUrl, [[0]]).catch(() => {
      console.log('Error loading audio');
    })

    this.mixingTimeUpdateWaveSurfer1.next(song);
    this.fadeOutTimeUpdateWaveSurfer1.next(song);
    this.fadeInTimeUpdateWaveSurfer1.next(song);
    console.log('seeking to 1', song.startTime, song.title);
    waveSurfer1?.setTime(song.startTime);
    if (song.fadeIn > 0) {
      waveSurfer1?.setVolume(0);
    } else {
      waveSurfer1?.setVolume(song.volume);
    }
    await waveSurfer1?.play();
    this.ActiveSong.next(song)
  }

  private async InitializeService(metadata: MoodMetadata) {

    this.subscribeToAllDownloadsCompleted();
    await this.processMetadata(metadata);
    this.startCleaningProcess(24 * 60 * 60 * 1000, metadata);
  }

  private async loadMetaAndStart() {
    const metadata = this.storageService.getMetadata();
    this.metadata = metadata;
    await this.InitializeService(metadata);
    const slot = this.findActiveSlot(metadata);
    if (!slot) return;
    await this.startPlayProcess(metadata, slot);
  }

  Stop() {
    clearInterval(this.checkForSongToPlayTimer);
    this.ActiveSong.next({} as Song);
    this.foundSong = false;
    this.waveSurfer1?.stop();
    this.waveSurfer1?.unAll();
    this.waveSurfer2?.stop();
    this.waveSurfer2?.unAll();
    this.mixingTimeUpdateWaveSurfer1.unsubscribe();
    this.mixingTimeUpdateWaveSurfer2.unsubscribe();
    this.fadeOutTimeUpdateWaveSurfer1.unsubscribe();
    this.fadeOutTimeUpdateWaveSurfer2.unsubscribe();
    this.fadeInTimeUpdateWaveSurfer1.unsubscribe();
    this.fadeInTimeUpdateWaveSurfer2.unsubscribe();

  }

  public NotifyToStop() {
    this.PlaylistStatus.next({
      startingTime: new Date(),
      status: PlaylistStatus.stopped,
    });
  }

  private async startPlayProcess(metadata: MoodMetadata, slot: Slot) {
    const activeSlot = slot;
    // Start Playing.
    if (activeSlot) {
      activeSlot.songs = activeSlot?.songs.filter(
        (s) => new Date(s.endDatetime) >= new Date()
      );
      if (activeSlot.songs.length === 0) {
        // Notify and return
        this.notify(PlaylistStatus.not_found, new Date());
        return;
      }
      // iterate through all activeSlot songs and add the value of audioURL and then return the song

      for (let i = 0; i < activeSlot.songs.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const audioBlob = await this.storageService.getAudio(
          activeSlot.songs[i].id.toString()
        );

        if (audioBlob) {
          activeSlot.songs[i].audioURL = URL.createObjectURL(audioBlob);
        } else if (!this.isProcessing) {
          try {
            const blob = await this.moodApiPlayerService.getSongById(
              activeSlot.songs[i].id
            );
            const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
            const audioUrl = URL.createObjectURL(audioBlob);
            activeSlot.songs[i].audioURL = audioUrl;
            await this.storageService.storeAudio(
              activeSlot.songs[i].id.toString(),
              activeSlot.songs[i].endDatetime,
              audioBlob
            );
            console.log('loading music from the API', activeSlot.songs[i].id);
          } catch (e: any) {
            console.error('error downloading song', e.message);
          }
        } else if (this.isProcessing) {
          console.log('isProcessing', this.isProcessing);
        }
      }
      this.playlistSongs.next([...activeSlot.songs]);
      await this.startCheckingForActiveSong(activeSlot, 1000, metadata);
    }
  }

  private startCheckingForActiveSong(
    slot: Slot,
    checkInterval: number,
    metadata: MoodMetadata
  ): Promise<void> {
    // Clear any existing check to avoid multiple checks running simultaneously
    clearTimeout(this.checkForSongToPlayTimer);

    // Wrap the recursive checking logic in a function
    const checkConditionAndResolve = (resolve: any) => {
      this.checkForSong(slot, metadata)
        .then((result) => {
          if (result) {
            // If the condition is met, resolve the promise
            resolve();
          } else {
            // If not, re-schedule the check after the specified interval
            this.checkForSongToPlayTimer = setTimeout(
              () => checkConditionAndResolve(resolve),
              checkInterval
            );
          }
        })
        .catch((error) => {
          console.error('Error checking for song:', error);
          // Optionally, reject the promise or handle the error appropriately
          // This example just logs the error and retries
          this.checkForSongToPlayTimer = setTimeout(
            () => checkConditionAndResolve(resolve),
            checkInterval
          );
        });
    };

    // Return a new promise and initiate the recursive checking
    return new Promise((resolve) => {
      checkConditionAndResolve(resolve);
    });
  }

  private async NotifyStatus(metadata: MoodMetadata, isFirstSlot = false) {
    const activeSlot = this.findActiveSlot(metadata);

    if (!activeSlot) {
      const futureSlot = this.findFutureSlot(metadata);

      // scenario that we exit.
      if (!futureSlot) {
        this.PlaylistStatus.next({
          startingTime: new Date(),
          status: PlaylistStatus.not_found,
        });
        return;
      }

      // there is a slot in the future starting
      if (futureSlot && !isFirstSlot) {
        const timeDifference = this.hourDifferenceFromNow(
          new Date(futureSlot.startTime)
        );
        if (timeDifference > 8) {
          this.notify(PlaylistStatus.long_wait, new Date(futureSlot.startTime));
          return;
        }

        if (timeDifference <= 8) {
          this.notify(
            PlaylistStatus.short_wait,
            new Date(futureSlot.startTime)
          );
          this.playlistSongs.next(futureSlot.songs);
          this.foundSong = false;
          await this.startCheckingForActiveSong(futureSlot, 1000, metadata);
          return;
        }
      }
    }
  }

  private hourDifferenceFromNow(datetime: Date): number {
    // Get the current datetime
    const currentDatetime = new Date();

    // Calculate the difference in milliseconds
    const timeDifferenceMs = datetime.getTime() - currentDatetime.getTime();

    // Calculate the number of hours
    const hoursDifference = timeDifferenceMs / (1000 * 60 * 60);

    // Check if the difference is greater than 8 hours
    return hoursDifference;
  }

  private startCleaningProcess(interval: number, metadata: MoodMetadata) {
    clearInterval(this.checkForObsoleteSongsTimer);
    this.checkForObsoleteSongsTimer = setInterval(() => {
      const futureSongs = metadata.slots
        .map((s) =>
          s.songs.filter((song) => {
            const currentDateTime = new Date(song.startDatetime);
            return (
              new Date(
                currentDateTime.setMinutes(currentDateTime.getMinutes() + 15)
              ) >= new Date()
            );
          })
        )
        .flat();
      this.storageService
        .disposeAnyObsoleteSongs(futureSongs.map((s) => s.id))
        .then((count) => {
          if (count > 0) console.log('disposed', count, 'songs');
        })
        .catch((e) => {
          console.error('error disposing songs', e.message);
        });
    }, interval);
  }

  private async processMetadata(metadata: MoodMetadata) {
    if (metadata.slots.length === 0) {
      this.notify(PlaylistStatus.not_found, new Date());
      return;
    }
    metadata = this.mergeStickySlots(metadata);
    if (!this.isProcessing)
      for (let i = 0; i < metadata.slots.length; i++) {
        const slot = metadata.slots[i];

        console.log('processing slot', i, new Date().toISOString());

        await this.processSongSlot(slot);

        console.log('slot processed', i, new Date().toISOString());

        if (i === 0) {
          await this.NotifyStatus(metadata, true);
        }
      }
    await this.NotifyStatus(metadata);
  }

  mergeStickySlots(metadata: MoodMetadata) {
    console.log('merging slots.');
    const newSlots = [];
    let activeSlot = metadata.slots[0];

    for (let i = 1; i < metadata.slots.length; i++) {
      const slot = metadata.slots[i];

      if (slot.startTime === activeSlot.endTime) {
        activeSlot.songs = activeSlot.songs.concat(slot.songs);
        activeSlot.endTime = slot.endTime;
      } else {
        newSlots.push(activeSlot);
        activeSlot = slot;
      }
    }

    newSlots.push(activeSlot); // Add the last activeSlot
    metadata.slots = newSlots;
    return metadata;
  }

  private async processSongSlot(slot: Slot) {
    const downloadBatchSize = slot.songs.length - 1 > 5 ? 5 : slot.songs.length - 1; // Define the size for the initial batch of songs

    const downloadSong = async (slotSongIndex: number) => {
      try {
        this.isProcessing = true;
        let songBlob: any;
        if (slotSongIndex > slot.songs.length - 1) {
          this.isProcessing = false
          return;
        }
        try {
          const songId = slot.songs[slotSongIndex].id.toString();
          songBlob = await this.storageService.getAudio(songId);
        } catch (e: any) {
          console.warn('cant get song from storage', e.message);
        }

        if (songBlob) {
          const audioUrl = URL.createObjectURL(songBlob);
          slot.songs[slotSongIndex].audioURL = audioUrl;
          console.log(
            'loading music from storage',
            slotSongIndex + 1,
            slot.songs[slotSongIndex].id
          );
        } else {
          const blob = await this.moodApiPlayerService.getSongById(
            slot.songs[slotSongIndex].id
          );
          const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
          const audioUrl = URL.createObjectURL(audioBlob);
          slot.songs[slotSongIndex].audioURL = audioUrl;
          await this.storageService.storeAudio(
            slot.songs[slotSongIndex].id.toString(),
            slot.songs[slotSongIndex].endDatetime,
            audioBlob
          );
          console.log(
            'loading music from the API',
            slotSongIndex + 1,
            slot.songs[slotSongIndex].id
          );
        }
      } catch (e: any) {
        console.error('error processing song', e.message);
      } finally {
        this.isProcessing = false;
      }
    };

    // Process the first batch of songs synchronously
    for (let i = 0; i < Math.min(downloadBatchSize, slot.songs.length); i++) {
      await downloadSong(i);
      this.InitialThresholdDownloaded.next(false);

    }

    // Signal that initial batch is ready for playback
    this.InitialThresholdDownloaded.next(true);

    // Introduce a delay to allow the application to react to the playback start signal
    await new Promise((resolve) => setTimeout(resolve, 500)); // 500 ms delay

    // Process the remaining songs sequentially in the background
    const processRemainingSongsSequentially = async () => {
      for (let i = downloadBatchSize; i < slot.songs.length; i++) {
        console.log('downloading song', i + 1, 'of', slot.songs.length);
        this.percentageToCompleteAllSongs.next(
          ((i + 1) / slot.songs.length) * 100
        );

        await downloadSong(i);
      }
      // Signal that all songs have been processed after the last song is downloaded
      this.slotProcessed$.next(slot);
    };

    processRemainingSongsSequentially().catch((e) =>
      console.error('Error processing remaining songs:', e)
    );

    // Do not wait for the background process to complete before moving on
    // The function ends here, allowing the application to proceed
  }

  private findActiveSlot(metadata: MoodMetadata): Slot | undefined {
    console.log('looking for active slot.');
    const currentTime = new Date();
    const activeSlot = metadata.slots.find((slot) => {
      return (
        new Date(slot.startTime) <= currentTime &&
        new Date(slot.endTime) > currentTime
      );
    });

    if (!activeSlot) return undefined;

    const nextSlotIndex = metadata.slots.indexOf(activeSlot) + 1;
    if (metadata.slots.length <= nextSlotIndex) return activeSlot;

    const nextSlot = metadata.slots[nextSlotIndex];
    if (!nextSlot) return activeSlot;

    if (activeSlot.endTime === nextSlot.startTime) {
      // Create a new slot object if immutability is a concern
      return {
        ...activeSlot,
        songs: activeSlot.songs.concat(nextSlot.songs),
        endTime: nextSlot.endTime,
      };
    }

    return activeSlot;
  }

  private findFutureSlot(metadata: MoodMetadata): Slot | undefined {
    console.log('looking for future slot.');
    return metadata.slots.find((slot: Slot) => {
      return (
        new Date(slot.startTime) > new Date() &&
        new Date(slot.endTime) > new Date()
      );
    });
  }

  private subscribeToAllDownloadsCompleted() {
    this.downloadCompleted.subscribe((completed) => {
      this.remainingSongsToDownload = this.remainingSongsToDownload - 1;
      this.DownloadingCountDown.next(this.remainingSongsToDownload);
      if (this.remainingSongsToDownload === 0) {
        this.allDownloadsCompleted.next(true);
        console.log('All Downloads Completed');
      }
    });
  }

  private notify(status: PlaylistStatus, timeStamp: Date) {
    console.log('Notify---> ', new Date().toISOString());

    this.PlaylistStatus.next({
      startingTime: timeStamp,
      status: status,
    });
  }

  async checkForSong(slot: Slot, metadata: MoodMetadata): Promise<boolean> {
    if (this.foundSong) {
      console.log('Song found');
      return true;
    }
    const songToPlay = this.findSongIndexToPlay(slot);
    console.log('Checking for song to play, song index to play', songToPlay);
    if (songToPlay > -1) {
      const activeSlot = this.findActiveSlot(metadata);
      if (!activeSlot) return false;
      this.foundSong = true;
      clearInterval(this.checkForSongToPlayTimer);

      const downloadBatchSize = 5; // Download the first 5 songs synchronously

      // Process the initial batch of songs synchronously
      for (
        let i = 0;
        i < Math.min(downloadBatchSize, activeSlot.songs.length);
        i++
      ) {
        await this.DownloadSong(activeSlot.songs[i]);
      }

      // Notify that the initial batch is ready for playback
      // Assuming there's some mechanism like this.InitialThresholdDownloaded.next(true); to notify

      // Process the remaining songs in the background, one at a time
      const processRemainingSongsInBackground = async () => {
        for (let i = downloadBatchSize; i < activeSlot.songs.length; i++) {
          // Wait for each song to be processed before continuing to the next
          await this.DownloadSong(activeSlot.songs[i]).catch((e) =>
            console.error('Error processing song in background:', e)
          );
        }
      };
      // Start this process without using 'await' to not block

      processRemainingSongsInBackground().then(r => {
          console.log('All songs downloaded');
        }
      );
      this.playlistSongs.next([...activeSlot.songs]);

      console.log('song to play', songToPlay);
      console.log(this.playlist);
      //notify the first song is going to play
      this.StartPlayingSlot.next(this.playlist[songToPlay]);
      this.notify(PlaylistStatus.playing, new Date(slot.startTime));

      console.log('clearing checkForSongToPlayTimer timer');
      return true;
    }
    return false;
  }

  async DownloadSong(song: any): Promise<void> {
    const audioBlob = await this.storageService.getAudio(song.id.toString());
    if (audioBlob) {
      song.audioURL = URL.createObjectURL(audioBlob);
    } else if (!this.isProcessing) {
      try {
        const blob = await this.moodApiPlayerService.getSongById(song.id);
        const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
        const audioUrl = URL.createObjectURL(audioBlob);
        song.audioURL = audioUrl;
        await this.storageService.storeAudio(
          song.id.toString(),
          song.endDatetime,
          audioBlob
        );
        console.log('loading music from the API', song.id);
      } catch (e: any) {
        console.error('error downloading song', e.message);
      }
    }
  }

  bundleActions: (() => Promise<any>)[] = [];

  private findSongIndexToPlay(activeSlot: Slot): number {
    // if (this.isMixing) return -1;
    const currentTime = new Date();

    for (let i = 0; i < activeSlot.songs.length; i++) {
      const song = activeSlot.songs[i];
      const songStartTime = new Date(song.startDatetime);
      const songEndTime = new Date(song.endDatetime);
      if (currentTime >= songStartTime && currentTime <= songEndTime) {
        return i;
      }
    }

    return -1;
  }

  // private lookForPlayList(): void {
  //   const metadata = this.metadata;
  //
  //   if (metadata) {
  //     const slot = this.findActiveSlot(metadata);
  //     if (!slot) return;
  //     this.startPlayProcess(metadata, slot)
  //       .then(() => {
  //       })
  //       .catch((e) => {
  //         console.error('error initializing', e.message);
  //       });
  //   } else {
  //     this.getMetadata?.subscribe((metadata) => {
  //       const slot = this.findActiveSlot(metadata);
  //       if (!slot) return;
  //
  //       this.startPlayProcess(metadata, slot)
  //         .then(() => {
  //         })
  //         .catch((e) => {
  //           console.error('error initializing', e.message);
  //         });
  //     });
  //   }
  // }

  private async downloadAndStoreSong(song: Song) {
    let audioUrl = '';
    try {
      const blob = await this.moodApiPlayerService.getSongById(song.id);
      const audioBlob = new Blob([blob], {type: 'audio/mpeg'});
      audioUrl = URL.createObjectURL(audioBlob);
      song.audioURL = audioUrl;

      await this.storageService.storeAudio(
        song.id.toString(),
        song.endDatetime,
        audioBlob
      );
      console.log('loading music from the API', song.id);
    } catch (e: any) {
      console.error('error downloading song', e.message);
    }
    console.log('music loaded audio url', audioUrl);

    return audioUrl;
  }

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

}
