import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SongPeaks } from '../interfaces/SongPeaks';
import { APP_CONFIG } from '../../../environments/environment';

const Library_API = `${APP_CONFIG.apiUrl}/api/VenueLibrary`;

@Injectable({
  providedIn: 'root',
})
export class VenueLibraryService {
  serviceHelper: any;
  constructor(private http: HttpClient) {}

  StreamSong(songId: number): Observable<ArrayBuffer> {
    return this.http.get(`${Library_API}/StreamSong?songId=${songId}`, {
      responseType: 'arraybuffer',
    });
  }

  getAudioStreamUrl(songId: number): string {
    return `${Library_API}/StreamSong?songId=${songId}`;
  }

  getSongPeaks(songId: number): Observable<any> {
    return this.http.get<SongPeaks>(
      `${Library_API}/GetSongPeaks?songId=${songId}`,
      {
        responseType: 'json',
        headers: {
          'X-Priority': 'High',
        },
      }
    );
  }
}
