/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  catchError,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  throwError,
  timer,
  of,
} from 'rxjs';

import {Router} from '@angular/router';
import {StorageService} from './storage.service';
import {AuthService} from './auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor, OnDestroy {
  isRefreshing = false;
  token = '';

  private destroy$ = new Subject<void>();
  private _storageService: StorageService | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private injector: Injector
  ) {
  }

  private get storageService(): StorageService {
    if (!this._storageService) {
      this._storageService = this.injector.get(StorageService);
    }
    return this._storageService;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.token = this.storageService.getToken();
    req =
      req.url.includes('/login') || req.url.includes('/refreshtoken')
        ? req
        : req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
          },
        });

    return next.handle(req).pipe(
      catchError((error: any) => {
        return this.handleError(req, next, error);
      })
    );
  }

  private handleError(
    req: HttpRequest<any>,
    next: HttpHandler,
    error: any
  ): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0: // Network errors
          return this.handleNetworkError(req, next);
        case 401: // Unauthorized
        case 403: // Forbidden
          if (
            !req.url.includes('/api/account/logout') &&
            !req.url.includes('/api/account/login') &&
            !req.url.includes('/api/account/registervenue')
          ) {
            return this.handle401Error(req, next);
          }
          break;
        default:
          return this.handleNetworkError(req, next);
          break;
      }
    }
    // If it's not a network or authorization error, or if the conditions for special handling are not met, rethrow the error.
    return throwError(() => error);
  }

  handleNetworkError(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the refresh token is still valid before retrying
    const refreshToken = this.storageService.getRefreshToken();
    const isTokenValid = this.getJwtExpiration(refreshToken);
    if (isTokenValid != null && isTokenValid > new Date()) {
      return timer(10000) // wait 10 second
        .pipe(
          switchMap(() => next.handle(request)),
          catchError((err) => {
            if (err instanceof HttpErrorResponse && err.status === 0) {
              return this.handleNetworkError(request, next);
            }
            return throwError(() => err);
          })
        );
    } else {
      localStorage.removeItem('isLoggedin');
      this.storageService.removeUser();
      this.router.navigate(['/login']);
      return throwError(
        () =>
          new Error('Network error: Refresh token expired, please login again.')
      );
    }
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log('handle401Error');
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.storageService.isLoggedIn()) {
        return this.authService.refreshToken().pipe(
          switchMap((data: any) => {
            this.storageService.setUser(data);
            this.isRefreshing = false;
            this.token = this.storageService.getToken();

            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.token}`,
              },
            });

            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService
              .logout()
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (data) => {
                  localStorage.removeItem('isLoggedin');
                  this.storageService.removeUser();
                  this.router.navigate(['/login']);
                },
                error: (error) => {
                  localStorage.removeItem('isLoggedin');
                  this.storageService.removeUser();
                  this.router.navigate(['/login']);
                },
                complete: () => {
                  localStorage.removeItem('isLoggedin');
                  this.storageService.removeUser();
                  this.router.navigate(['/login']);
                },
              });

            return throwError(() => error);
          })
        );
      } else {
        this.isRefreshing = false;
        localStorage.removeItem('isLoggedin');
        this.router.navigate(['/login']);
      }
    }

    return next.handle(request);
  }

  getJwtExpiration(token: string | null) {
    if (!token) {
      return new Date(1970, 1, 1);
    }

    // Split the JWT into its parts: Header, Payload, Signature
    const base64Url = token.split('.')[1];
    if (!base64Url) {
      return new Date(1970, 1, 1);
    }

    // Replace URL-specific characters with base64 standard characters
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
      // Decode base64 string
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      // Parse JSON from decoded string
      const payload = JSON.parse(jsonPayload);

      // Return the exp (expiration time) field from the payload
      return payload.exp ? new Date(payload.exp * 1000) : null;
    } catch (e) {
      console.error('Failed to decode JWT:', e);
      return null;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export const httpInterceptorProviders =
  {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true}
;
