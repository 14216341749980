/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MusicService} from '../core/services/music.service';
import {Song} from '../core/interfaces/Song';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  catchError,
  switchMap,
  takeUntil, throttleTime,
} from 'rxjs';
import {MoodPlayerService} from '../core/services/mood-player.service';
import {StorageService} from '../core/services/storage.service';
import {MoodMetadata} from '../core/interfaces/MoodMetadata';
import {PlaylistPlayerService} from '../core/services/playlist-player.service';
import NoSleep from '@zakj/no-sleep';
import {APP_CONFIG} from '../../environments/environment';

import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import {
  faVolumeHigh,
  faVolumeOff,
  faVolumeXmark,
  faPlay,
  faStop,
  faHeartBroken,
  faHeart as solidHeart,
  faThumbsUp as likeActive,
  faThumbsDown as dislikeActive,
  faPause,
} from '@fortawesome/free-solid-svg-icons';
import {
  faHeart as regularHeart,
  faUser,
  faThumbsDown as dislike,
  faThumbsUp as like,
} from '@fortawesome/free-regular-svg-icons';
import {PlaylistStatus} from '../core/interfaces/IPlaylistStatus';
import {NotifyPopupComponent} from '../notify-popup/notify-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {StopMusicPopupComponent} from '../stop-music-popup/stop-music-popup.component';
import WaveSurfer from 'wavesurfer.js';
import {AuthService} from '../core/services/auth.service';
import {GoogleTagManagerImplService} from '../core/services/google-tag-manager.service';
import {PlaylistPlayerV2Service} from "../core/services/playlist-player-v2.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('likeAnimation', [
      state('liked', style({transform: 'scale(1.0)', color: '#ff3f58'})),
      transition('* => liked', [
        animate('300ms ease-in', style({transform: 'scale(1.2)'})),
        animate('300ms ease-out', style({transform: 'scale(1)'})),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  songs: BehaviorSubject<Song[]> = new BehaviorSubject<Song[]>([]);
  private destroy$ = new Subject<void>();
  private playlist: Song[] = [];
  CanStartPlaying = true;
  totalDownloads = 0;
  faVolumeHigh = faVolumeHigh;
  faVolumeOff = faVolumeXmark;
  faUser = faUser;
  isSoundOn = true;
  like = like;
  isShortWait = false;
  faPause = faPause;
  shortWaitTime = '';
  likeActive = likeActive;
  dislike = dislike;
  dislikeActive = dislikeActive;
  solidHeart = solidHeart;
  faPlay = faPlay;
  faStop = faStop;
  faHeartBroken = faHeartBroken;
  regularHeart = regularHeart;
  hasSong = true;
  isPlaying = false;
  isLiked = false;
  isDisliked = false;
  startPlaying = new BehaviorSubject<boolean>(false);
  remainingSongsToDownload = -1;
  isDownloading = false;
  currentTime = 0;
  likeState = 'unliked';
  metadata: MoodMetadata | null = null;
  activeSong: Song | null = null;
  isPulsing = false;
  songVolume = 0;
  playerVolume = 0.85;
  DownloadingFirstSlot = true;
  DownloadedAllSongs = false;
  isPlayProcessActive = false;
  notifySubscription: Subscription | null = null;
  subscriptions: Subscription[] = [];
  isProfileOpen = false;
  assetPath = '';
  noSleep = new NoSleep();
  private refreshTokenInterval = 30; // Default to almost 30seconds
  private refreshTokenSubscription: Subscription;
  private refreshTokenTrigger = new Subject<void>();
  downloadingStatus = 'Downloading songs';
  downloadingAllStatus = 'Preparing offline mode';
  percentageOfAllSongs = 0;
  isPreFetchingNextSong = false;
  isFetchingNextSong = false;
  videoSrc = 'assets/pip_Groovit.mp4';

  constructor(
    private router: Router,
    private musicService: MusicService,
    private moodPlayerService: MoodPlayerService,
    private storageService: StorageService,
    private playlistPlayerServiceV2: PlaylistPlayerV2Service,
    public dialog: MatDialog,
    private authService: AuthService,
    private gaService: GoogleTagManagerImplService
  ) {
    this.refreshTokenSubscription = this.refreshTokenTrigger
      .pipe(
        // Attempt to refresh the token
        switchMap(() =>
          this.authService.refreshToken().pipe(
            catchError((error) => {
              console.error('Error refreshing token', error);
              this.triggerNextRefresh(30000); // Retry after a short delay if there's an error
              throw error; // Rethrow error to handle it in the subscription's error callback
            })
          )
        )
      )
      .subscribe({
        next: (response: any) => {
          // console.log('refresh token response', response);
          if (this.storageService.getToken() !== response.accessToken) {
            console.log('refresh token', response);
            this.storageService.setUser(response);
          }
          // Calculate new refresh interval and trigger next refresh
          const nextRefreshInterval = Math.min(
            (response.expiresIn - 10) * 1000,
            30000
          );
          //console.warn('Next refresh in', nextRefreshInterval);
          this.triggerNextRefresh(nextRefreshInterval);
        },
        error: (error) => {
          console.error('Unhandled error refreshing token', error);
          this.triggerNextRefresh(30000); // Set a fail-safe refresh on error
        },
      });

    // Trigger the initial refresh
    this.triggerNextRefresh(0);

    this.songIsReady$.subscribe((audioUrl) => {
      this.waveSurferA = WaveSurfer.create({
        backend: 'WebAudio',
        container: '#waveformA',
        waveColor: 'white',
        cursorColor: 'yellow',
        barHeight: 0.5,
        height: 70,
        url: audioUrl,
        peaks: [[0]],
        duration: 200,
        fetchParams: {
          headers: {
            Authorization: 'Bearer ' + this.storageService.getToken(),
          },
        },
      });
      const waveSurferUnsubscribeA = this.waveSurferA.on('ready', () => {
        console.log('waveSurferA ready and trying to play from homee')
        this.waveSurferA?.play().catch((e) => {
          console.log('Error playing audio');
          console.log(e);
        }).finally(() => {
          if (waveSurferUnsubscribeA) {
            waveSurferUnsubscribeA();
          }
        });
      });
    });
  }

  async togglePictureInPicture() {
    if(this.isMobileDevice()) {
      return;
    }

    const video: HTMLVideoElement = document.getElementById('videoElement') as HTMLVideoElement;
    // video.loop = true;
    // video.play();
    try {
      if (document.pictureInPictureElement) {
        // Exit PiP if it's already active
        await document.exitPictureInPicture();
      } else {
        // Request PiP mode
        await video.requestPictureInPicture();
      }
    } catch (error) {
      console.error('Error with Picture-in-Picture:', error);
    }
  }

  getOS() {
    const userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i;
    let os = null;

    if (macosPlatforms.test(userAgent)) {
      os = "macos";
    } else if (iosPlatforms.test(userAgent)) {
      os = "ios";
    } else if (windowsPlatforms.test(userAgent)) {
      os = "windows";
    } else if (/android/.test(userAgent)) {
      os = "android";
    } else if (!os && /linux/.test(userAgent)) {
      os = "linux";
    }

    return os;
  }

  isMobileDevice(): boolean {
    // Regex pattern for mobile user agents
    const mobileRegex = /iPhone|iPad|iPod|Android/i;
    return mobileRegex.test(navigator.userAgent);
  }

  private triggerNextRefresh(delay: number) {
    setTimeout(() => this.refreshTokenTrigger.next(), delay);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
    this.destroy$.next();
    this.destroy$.complete();
  }

  setSongVolume($event: Event) {
    console.log(
      'song changed volume',
      ($event.target as HTMLInputElement).valueAsNumber
    );
    this.playerVolume = ($event.target as HTMLInputElement).valueAsNumber / 100;
    // this.playlistPlayerService.SetUIVolume(this.playerVolume);
  }

  toggleSound() {
    this.isSoundOn = !this.isSoundOn;
  }

  togglePulseAnimation() {
    this.isPulsing = !this.isPulsing;
  }

  formatLabel(value: number): string {
    if (value >= 100) {
      return `${Math.round(value)}`;
    }

    return `${value}`;
  }

  ngOnInit(): void {
    this.assetPath = APP_CONFIG.production ? 'assets' : 'assets';
    this.DownloadingFirstSlot = true;
    document.addEventListener('click', this.enableNoSleep.bind(this), false);
    this.GetMetadata();
    this.SubscribeToFirstSlotDownloaded();
    this.SubscribeToDownloadingCountdown();
    this.SubscribeToAllDownloadsCompleted();
    this.subscribeToPlaylistStatus();
    this.playlistPlayerServiceV2.percentageToCompleteAllSongs.subscribe(
      (percentage) => {
        console.log('percentageToCompleteAllSongs', percentage);
        console.log(this.DownloadingFirstSlot, this.DownloadedAllSongs)
        this.percentageOfAllSongs = percentage;
        if (this.percentageOfAllSongs > 95) {
          setTimeout(() => {
            this.percentageOfAllSongs = 0
          }, 5000);
        }
      }
    );
    this.playlistPlayerServiceV2.PrefetchNextSongAudio.subscribe((isPreFetching: boolean) => {
        this.isPreFetchingNextSong = isPreFetching;
      }
    );
    this.playlistPlayerServiceV2.FetchingNextSongAudio.subscribe((isFetching: boolean) => {
        this.isFetchingNextSong = isFetching;
      }
    );
    this.playlistPlayerServiceV2.ActiveSong.subscribe((song) => {
        console.log('HomeComponent ngOnInit ActiveSong', song);
        this.activeSong = song;
      }
    );
    this.playlistPlayerServiceV2.slotProcessed$.subscribe((slot: any) => {
        this.DownloadedAllSongs = true;
        console.log('slot processed', slot);
      }
    );

  }

  initialSongSlotSize = 5
  percentageOfInitialSlot = 0.0

  private SubscribeToFirstSlotDownloaded() {
    this.subscriptions.push(
      this.playlistPlayerServiceV2.InitialThresholdDownloaded.subscribe(
        (completed) => {
          this.percentageOfInitialSlot = this.percentageOfInitialSlot + 0.18;
          console.log('percentageOfInitialSlot', this.percentageOfInitialSlot);
          this.DownloadingFirstSlot = true;

          if (completed) {
            console.log('FirstBundleFinished', completed);
            this.DownloadingFirstSlot = false;
          }
        }
      )
    );
  }

  private subscribeToPlaylistStatus() {
    this.playlistPlayerServiceV2.PlaylistStatus.pipe(
      takeUntil(this.destroy$)
    ).subscribe((pl) => {
      if (pl !== null)
        console.log(
          'HomeComponent ngOnInit PlaylistStatus',
          pl?.startingTime,
          PlaylistStatus[pl.status]
        );

      this.isPlayProcessActive = false;
      switch (pl?.status) {
        case PlaylistStatus.stopped:
          this.isPlaying = false;
          this.isPulsing = false;
          this.isShortWait = false;
          this.activeSong = null;
          this.currentTime = 0;
          document.removeEventListener(
            'click',
            this.enableNoSleep.bind(this),
            false
          );
          break;
        case PlaylistStatus.long_wait:
          this.isPlaying = false;
          this.isPulsing = false;
          this.isShortWait = false;
          this.activeSong = null;
          this.currentTime = 0;
          // eslint-disable-next-line no-case-declarations
          this.dialog.open(NotifyPopupComponent);
          break;
        case PlaylistStatus.not_found:
          this.isPlaying = false;
          this.isPulsing = false;
          this.isShortWait = false;
          this.activeSong = null;
          this.DownloadingFirstSlot = false;
          this.currentTime = 0;
          // eslint-disable-next-line no-case-declarations
          this.dialog.open(NotifyPopupComponent);
          break;
        case PlaylistStatus.short_wait:
          this.isPlaying = false;
          this.isPulsing = false;
          this.isShortWait = true;
          this.activeSong = null;
          this.currentTime = 0;

          this.shortWaitTime = pl?.startingTime.toLocaleTimeString() ?? '';
          break;
        case PlaylistStatus.playing:
          this.isPlaying = true;
          this.isPulsing = true;
          this.isShortWait = false;
          break;
        default:
          console.warn(
            'HomeComponent ngOnInit UNKNOWN !!!! PlaylistStatus',
            pl
          );
          break;
      }
    });
  }

  private GetMetadata() {
    this.moodPlayerService
      .getPlaylistMetadata()
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe((m: MoodMetadata) => {
        console.log('HomeComponent ngOnInit metadata', m);
        this.metadata = m;
        this.storageService.setMetadata(this.metadata);
        this.DownloadingFirstSlot = false;
        this.isPlayProcessActive = false;
        this.isShortWait = false;
      });
  }

  private SubscribeToAllDownloadsCompleted() {
    this.playlistPlayerServiceV2.allDownloadsCompleted.subscribe((completed) => {
      console.log('all downloads completed!');
      this.DownloadedAllSongs = true;
    });

  }


  // Display the number of songs left to download && if the number of songs downloaded is greater than or equal to the number of songs to cache before start playing,
  // then set this.CanStartPlaying to true.
  private SubscribeToDownloadingCountdown() {
    this.playlistPlayerServiceV2.DownloadingCountDown.subscribe((count) => {
      this.totalDownloads++;
      this.CanStartPlaying = true;
      //this.totalDownloads >= APP_CONFIG.songsToCacheBeforeStartPlaying;
      this.isDownloading = count > 0;

      // console.log('HomeComponent ngOnInit DownloadingCountDown', count);
      this.remainingSongsToDownload = count;
    });
  }

  private enableNoSleep() {
    document.removeEventListener('click', this.enableNoSleep.bind(this), false);
    this.noSleep.enable();
  }

  waveSurferA: WaveSurfer | null = null;
  waveSurferB: WaveSurfer | null = null;

  songIsReady$: Subject<string> = new Subject<string>();

  async play() {
    this.gaService.event('play', 'Player');
    this.togglePictureInPicture();
    if (this.isPlayProcessActive) {
      console.log('skipping playing... is playprocess active');
      return;
    }

    this.isPlayProcessActive = true;
    if (this.waveSurferA === null) {
      this.waveSurferA = WaveSurfer.create({
        container: '#waveformA',
        waveColor: 'white',
        cursorColor: 'yellow',
        barHeight: 0.5,
        height: 70,
        url: '',
        peaks: [[0]],
        duration: 200,
        fetchParams: {
          headers: {
            Authorization: 'Bearer ' + this.storageService.getToken(),
          },
        },
      });
    }
    if (this.waveSurferB === null) {
      this.waveSurferB = WaveSurfer.create({
        backend: 'WebAudio',
        container: '#waveformB',
        waveColor: 'white',
        cursorColor: 'yellow',
        barHeight: 0.5,
        height: 70,
        url: '',
        peaks: [[0]],
        duration: 200,
        fetchParams: {
          headers: {
            Authorization: 'Bearer ' + this.storageService.getToken(),
          },
        },
      });
    }
    // Still downloading first slot
    if (this.DownloadingFirstSlot) {
      console.log('skipping playing... is downloading first slot active');
      return;
    }
    // this.waveSurferA.on('audioprocess', (time: number) => {
    //   this.currentTime = (100 * time) / (this.activeSong?.duration ?? time);
    //   this.songVolume = this.playerVolume * 100;
    //   this.playlistPlayerService.waveSurferServiceA.setTime(time);
    // });
    // this.waveSurferB.on('audioprocess', (time: number) => {
    //   this.currentTime = (100 * time) / (this.activeSong?.duration ?? time);
    //   this.songVolume = this.playerVolume * 100;
    //   this.playlistPlayerService.waveSurferServiceB.setTime(time);
    // });
    console.log('HomeComponent play', new Date().toISOString());

    // this.playlistPlayerService.setWaveSurfers(
    //   this.waveSurferA,
    //   this.waveSurferB
    // );
    this.DownloadingFirstSlot = true;
    // await this.playlistPlayerService.Start(
    //   this.moodPlayerService.getPlaylistMetadata()
    // );
    this.playlistPlayerServiceV2.CurrentTime.pipe(
      throttleTime(1000) // 1000ms = 1 second
    ).subscribe((time) => {
      console.log('HomeComponent play CurrentTime', time);
      this.currentTime = time;
    });
    await this.playlistPlayerServiceV2.Start(
      this.moodPlayerService.getPlaylistMetadata(),
      this.waveSurferA,
      this.waveSurferB,
    );
    console.log('start ended');
  }

  stop() {
    this.gaService.event('stop', 'Player');
    this.dialog.open(StopMusicPopupComponent);
  }

  toggleLike() {
    if (this.isLiked == false) {
      this.isLiked = true;
      this.gaService.event('like', 'Player', 'like', '1');
      if (this.isDisliked == true) {
        this.isDisliked = false;
      }
    } else if (this.isLiked == true) {
      this.isLiked = false;
      this.gaService.event('like', 'Player', 'like', '0');

    }
  }

  toggleDislike() {
    if (this.isDisliked == false) {
      this.isDisliked = true;
      this.gaService.event('dislike', 'Player', 'dislike', '1');
      if (this.isLiked == true) {
        this.isLiked = false;
      }
    } else if (this.isDisliked == true) {
      this.isDisliked = false;
      this.gaService.event('dislike', 'Player', 'dislike', '0');

    }
  }

  async gotToProfile() {
    this.gaService.trackScreenOpen('Profile');
    await this.router.navigate(['/profile']).catch(() => {
    });
  }

  openProfile() {
    console.log('openProfile');
    this.isProfileOpen = !this.isProfileOpen;
    console.log(this.isProfileOpen);
  }
}
