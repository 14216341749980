import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser'; // Import the Sentry module

import {AppModule} from './app/app.module';
import {APP_CONFIG} from './environments/environment';

if (APP_CONFIG.production) {
  enableProdMode();
}

if (APP_CONFIG.production) {
  Sentry.init({
    dsn: "https://a8b0bfd562c7b09a4ea2ab858435b8a2@o4507012396351488.ingest.us.sentry.io/4507106087403520",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/(?:.*\.)?groovit\.io\/.*/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
if (APP_CONFIG.environment !== 'LOCAL') {
  console.log = function () {
  };
} else {
  console.log('development mode');
}


enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: false,
  })
  .catch((err) => console.error(err));
