import { Component } from '@angular/core';

@Component({
  selector: 'app-storage-popup',
  templateUrl: './storage-popup.component.html',
  styleUrls: ['./storage-popup.component.scss']
})
export class StoragePopupComponent {

}
