import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { VenueLibraryService } from '../core/services/venue-library.service';
import { StorageService } from '../core/services/storage.service';
import { Song } from '../core/interfaces/Song';
import WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'app-mini-player',
  templateUrl: './mini-player.component.html',
  styleUrls: ['./mini-player.component.scss'],
})
export class MiniPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('waveform', { static: false }) waveformRef: ElementRef | null =
    null;
  @Input() startPlaying: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  @Input() songs: BehaviorSubject<Song[]> = new BehaviorSubject<Song[]>([]);
  private waveSurfer: WaveSurfer | null = null;
  currentTime = 0;
  songIndex = 0;
  duration = 0;
  playerError = '';
  region: any;
  playerLoaded = false;
  alert1closed = false;
  peaks: (Float32Array | number[])[] | undefined;
  canBeVisible = true;
  loading = true;
  song: Song | null = null;
  private destroy$ = new Subject<void>();
  private routerSubscription: Subscription;
  songList: Song[] = [];
  token: string;
  constructor(
    private router: Router,
    private venueLibraryService: VenueLibraryService,
    private storageService: StorageService
  ) {
    this.token = this.storageService.getToken();
    this.routerSubscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.canBeVisible =
          val.url.includes('dashboard') ||
          (val.url.includes('playlist') &&
            val.url.includes('playlists') == false) ||
          val.url === '/';
        this.stopAudio();
      }
    });

    setTimeout(() => {
      this.loading = false;
    }, 50);
  }
  ngOnInit(): void {
    this.songs.subscribe((songs) => {
      if (songs.length > 0) {
        console.log('songs pass to mini player songs', songs);
        this.song = songs[0];
        this.songList = songs;
        console.log(this.song);
        this.startPlaying.subscribe((startPlaying) => {
          console.log('startPlaying', startPlaying);
          if (startPlaying === true) {
            this.playAudio();
          } else {
            this.stopAudio();
          }
        });
        this.venueLibraryService
          .getSongPeaks(this.song?.id ?? 0)
          .pipe(takeUntil(this.destroy$))
          .subscribe((peaks) => {
            this.peaks = peaks;

            if (this.waveSurfer === null) {
              this.playerError = '';
              this.initializePlayer(this.song!.id);
            } else {
              this.waveSurfer
                .load(
                  this.venueLibraryService.getAudioStreamUrl(
                    this.song?.id ?? 0
                  ),
                  this.peaks,
                  this.song?.duration ?? 0
                )
                .catch((error) => {
                  console.log('Error loading audio', error);
                });
            }
          });
      }
    });
  }

  initializePlayer(songId: number) {
    this.waveSurfer = WaveSurfer.create({
      container: this.waveformRef?.nativeElement,
      waveColor: 'white',
      progressColor: 'grey',
      cursorColor: 'yellow',
      height: 30,
      barHeight: 0.5,

      hideScrollbar: true,
      fetchParams: {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      },
    });

    this.waveSurfer.setVolume(0.85);

    const audioStreamUrl = this.venueLibraryService.getAudioStreamUrl(songId);
    let songLoaded = true;

    this.waveSurfer.on('ready', () => {
      if (this.playerError === '') {
        this.playerLoaded = true;
      }
    });
    this.waveSurfer.on('finish', () => {
      console.log('finish song going to the next one!!');
      this.songIndex = this.songIndex + 1;
      console.log(
        `previous id: ${this.song?.id ?? 'undefined'}, next song id: ${
          this.songList[this.songIndex].id
        }`
      );
      this.song = this.songList[this.songIndex];
      this.venueLibraryService
        .getSongPeaks(this.song?.id ?? 0)
        .pipe(takeUntil(this.destroy$))
        .subscribe((peaks: any) => {
          this.peaks = peaks;

          if (this.waveSurfer === null) {
            this.playerError = '';
            this.initializePlayer(this.song!.id);
          } else {
            this.waveSurfer
              .load(
                this.venueLibraryService.getAudioStreamUrl(this.song?.id ?? 0),
                this.peaks,
                this.song?.duration ?? 0
              )
              .catch((error) => {
                console.log('Error loading audio', error);
              });
          }
          this.playAudio();
        });
    });

    this.waveSurfer.on('audioprocess', () => {
      this.currentTime = this.waveSurfer?.getCurrentTime() ?? 0;
    });

    if (songLoaded) {
      this.waveSurfer
        ?.load(audioStreamUrl, this.peaks, this.song?.duration)
        .catch((error) => {
          console.log('Error loading audio', error);
        });
    }
  }

  handleLoadError(error: Error) {
    if (error.message) {
      this.playerError = error.message;
      console.error('Failed to load the audio:', error.message);
    }
  }

  closeAlert() {
    this.playerError = '';
  }

  playAudio() {
    this.waveSurfer?.play().catch(() => {
      console.log('Error playing audio');
    });
  }

  pauseAudio() {
    this.waveSurfer?.pause();
  }

  stopAudio() {
    if (this.waveSurfer) {
      this.waveSurfer?.stop();
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.waveformRef?.nativeElement.remove();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
